export enum VirtualCardStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  USED = 'USED',
  CANCELLED = 'CANCELLED',
  REFUNDED = 'REFUNDED',
  EXPIRED = 'EXPIRED',
  DISPUTED = 'DISPUTED',
}

export enum MultiUseCardStatus {
  LOCKED = 'LOCKED',
  UNLOCKED = 'UNLOCKED',
  BRAND_TIED = 'BRAND_TIED',
  READY_FOR_USE = 'READY_FOR_USE',
}

export enum CancelVirtualCardReason {
  USER_REQUESTED_CANCEL = 'USER_REQUESTED_CANCEL',
}

export enum VirtualCardVendor {
  LITHIC = 'LITHIC',
  I2C = 'I2C',
  HIGHNOTE = 'HIGHNOTE',
  MARQETA = 'MARQETA',
}

export enum VirtualCardExternalOfferStoreId {
  MARQETA_MANAGED_BY = 'MARQETA_MANAGED_BY',
  I2C_COMMERCIAL_PREPAID = 'I2C_COMMERCIAL_PREPAID',
  LITHIC_COMMERCIAL_DEBIT = 'LITHIC_COMMERCIAL_DEBIT',
  LITHIC_COMMERCIAL_PREPAID = 'LITHIC_COMMERCIAL_PREPAID',
  HIGHNOTE_CFSB_VISA = 'HIGHNOTE_CFSB_VISA',
  HIGHNOTE_CFSB_MASTERCARD = 'HIGHNOTE_CFSB_MASTERCARD',
  HIGHNOTE_PREPAID_VISA = 'HIGHNOTE_PREPAID_VISA', // deprecated (defaults to CFSB visa)
  HIGHNOTE_PREPAID_MASTERCARD = 'HIGHNOTE_PREPAID_MASTERCARD', // deprecated (defaults to CFSB mc)
  HIGHNOTE_SUTTON_VISA = 'HIGHNOTE_SUTTON_VISA',
  HIGHNOTE_SUTTON_MASTERCARD = 'HIGHNOTE_SUTTON_MASTERCARD',
  HIGHNOTE_SUTTON_VISA_PREPAID = 'HIGHNOTE_SUTTON_VISA_PREPAID',
  HIGHNOTE_SUTTON_MASTERCARD_DEBIT = 'HIGHNOTE_SUTTON_MASTERCARD_DEBIT',
}

export enum VirtualCardType {
  SINGLE_USE = 'SINGLE_USE',
  MULTI_USE = 'MULTI_USE',
}

export enum VirtualCardSpendLimitDuration {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  ANNUAL = 'ANNUAL',
  LIFETIME = 'LIFETIME',
}

export enum VirtualCardProvider {
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA',
}

export enum VirtualCardTransactionListType {
  PURCHASE = 'PURCHASE',
  REFUND = 'REFUND',
  DECLINE = 'DECLINE',
}

export enum VirtualCardActivityTransactionStatus {
  DECLINED = 'DECLINED',
  APPROVED = 'APPROVED',
}
