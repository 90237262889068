import { ActionType } from 'typesafe-actions';
import { mapBackupPaymentMethod } from '../../utils';

import * as user from './actions';
import {
  INIT_SEATS,
  SELECT_SEAT,
  INIT_VOUCHERS,
  INIT_USER,
  RESET_USER_STATE,
  SET_BALANCE,
  SET_INITIAL_STATE,
  SET_PREFERRED_PAYMENT_METHODS,
  SET_LIMIT,
  SET_CAMPAIGN_PHASED_ACCESS,
  SET_USER_LOADING,
} from './constants';

import {
  User,
  Seat,
  Voucher,
  SpendingLimitResponse,
  UserCampaignPhasedAccessPayload,
  PreferredPaymentMethod,
} from '../../library/interfaces';

export type UserAction = ActionType<typeof user>;

export type UserState = {
  user: User;
  seats: Seat[];
  currentSeat: Seat;
  vouchers: Voucher[];
  spendingLimits: SpendingLimitResponse;
  campaignsAccess: Record<string, UserCampaignPhasedAccessPayload>;
  userPaymentPreferences: Record<string, PreferredPaymentMethod>;
  loading: boolean;
};

const defaultState: UserState = {
  user: {} as User,
  seats: [] as Seat[],
  currentSeat: {} as Seat,
  vouchers: [] as Voucher[],
  spendingLimits: {} as SpendingLimitResponse,
  campaignsAccess: {} as Record<string, UserCampaignPhasedAccessPayload>,
  userPaymentPreferences: {} as Record<string, PreferredPaymentMethod>,
  loading: true,
};

export default (state = defaultState, action: UserAction): UserState => {
  switch (action.type) {
    case INIT_SEATS:
      return {
        ...state,
        seats: action.payload,
      };
    case SELECT_SEAT:
      return {
        ...state,
        currentSeat: action.payload,
      };
    case INIT_VOUCHERS:
      return {
        ...state,
        vouchers: action.payload,
      };
    case INIT_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_BALANCE:
      return {
        ...state,
        user: { ...state.user, balance: action.payload },
      };
    case SET_LIMIT:
      return {
        ...state,
        spendingLimits: action.payload,
      };
    case SET_INITIAL_STATE: {
      const preferredPaymentMethods: Record<string, PreferredPaymentMethod> = {};
      if (action.payload?.user_payment_preferences) {
        action.payload?.user_payment_preferences.forEach((method) => {
          preferredPaymentMethods[method.payment_method_role] = method;
        });
      }

      return {
        ...state,
        user: action.payload.user,
        seats: action.payload.seats,
        currentSeat: action.payload.currentSeat,
        userPaymentPreferences: preferredPaymentMethods,
      };
    }
    case SET_CAMPAIGN_PHASED_ACCESS: {
      const campaignsAccess: Record<string, UserCampaignPhasedAccessPayload> = {};
      action.payload.forEach((campaign) => {
        campaignsAccess[campaign.campaign_name] = campaign;
      });
      return {
        ...state,
        campaignsAccess,
      };
    }
    case SET_USER_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case SET_PREFERRED_PAYMENT_METHODS: {
      const preferredPaymentMethods: Record<string, PreferredPaymentMethod> = {};
      if (action.payload?.user_payment_preferences) {
        action.payload?.user_payment_preferences.forEach((method) => {
          preferredPaymentMethods[method.payment_method_role] = method;
        });
      }
      return {
        ...state,
        userPaymentPreferences: preferredPaymentMethods,
      };
    }
    case RESET_USER_STATE:
      return {
        ...defaultState,
      };
    default:
      return state;
  }
};
