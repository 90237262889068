import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useRiskified from '../services/gateways/riskified/useRiskified';
import useVirtualCard from '../services/virtual-card/useVirtualCard';
import { VirtualCardMerchantOfferResponse } from '../library/interfaces';
import { setVirtualCardOffers } from '../store/virtual-card/actions';

export default function AppInitialize() {
  const { initializeRiskified } = useRiskified();
  const dispatch = useDispatch();

  const { getVirtualCardMerchantOffers } = useVirtualCard();

  async function fetchVirtualCardMerchantOffers() {
    const merchantOfferRes = await getVirtualCardMerchantOffers();
    const merchantOffers = merchantOfferRes as VirtualCardMerchantOfferResponse;
    dispatch(setVirtualCardOffers(merchantOffers));
  }

  useEffect(() => {
    async function initApp() {
      await initializeRiskified();
      await fetchVirtualCardMerchantOffers();
    }
    initApp();
  }, []);

  return null;
}
