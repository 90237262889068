import ky from 'ky';
import { RootStateOrAny, useSelector } from 'react-redux';
import useCommonRequestOptions from '../useCommonRequestOptions';
import { AuthResponse, ForgotPasswordPayload, LoginPayload, LogoutPayload } from '../../library/interfaces';

export default function useAuth() {
  const { getCommonRequestOptions } = useCommonRequestOptions();
  // Imported only for logout functionality. Will use current token, even if it's expired/about to expire.
  // Logout route doesn't care about token expiration.
  const { accessToken } = useSelector((state: RootStateOrAny) => state.auth);

  async function login(loginPayload: LoginPayload): Promise<AuthResponse> {
    return ky.post('login', { ...getCommonRequestOptions('user'), json: loginPayload }).json();
  }

  async function loginSocialAuth(loginPayload: LoginPayload): Promise<AuthResponse> {
    return ky.post('social-auth', { ...getCommonRequestOptions('user'), json: loginPayload }).json();
  }

  async function logout(logoutPayload: LogoutPayload): Promise<AuthResponse> {
    return ky
      .post('logout', { ...getCommonRequestOptions('user', { token: accessToken }), json: logoutPayload })
      .json();
  }

  async function forgotPassword(forgotPasswordPayload: ForgotPasswordPayload): Promise<AuthResponse> {
    return ky.post('password/forgot', { ...getCommonRequestOptions('user'), json: forgotPasswordPayload }).json();
  }

  async function changePassword(changePasswordPayload: { password: string }, token: string): Promise<AuthResponse> {
    return ky
      .post('password/change', { ...getCommonRequestOptions('user', { token }), json: changePasswordPayload })
      .json();
  }

  async function verifyMFA(authCode: string, token?: string): Promise<AuthResponse> {
    return ky.post('2fa/verify', { ...getCommonRequestOptions('auth', { token }), json: { authCode } }).json();
  }

  async function requestResendMFA(token: string): Promise<AuthResponse> {
    return ky.get('2fa/resend', { ...getCommonRequestOptions('auth', { token }) }).json();
  }

  return {
    login,
    loginSocialAuth,
    logout,
    forgotPassword,
    changePassword,
    verifyMFA,
    requestResendMFA,
  };
}
