export const SET_MX_GUID = 'payment/SET_MX_GUID';
export const SET_MX_MEMBERS = 'payment/SET_MX_MEMBERS';
export const SET_MX_INSTITUTIONS = 'payment/SET_MX_INSTITUTIONS';
export const SET_MX_VERIFY_MEMBERS = 'payment/SET_MX_VERIFY_MEMBERS';
export const SET_MX_BANK_BALANCES = 'payment/SET_MX_BANK_BALANCES';
export const ADD_ACH_METHODS = 'payment/ADD_ACH_METHODS';
export const SET_SHOW_NEW_BANK_ACCOUNT_MODAL = 'payment/SET_SHOW_NEW_BANK_ACCOUNT_MODAL';
export const SET_SHOW_VERIFY_ACCOUNT_MODAL = 'payment/SET_SHOW_VERIFY_ACCOUNT_MODAL';
export const SET_SHOW_VERIFY_ACCOUNT_MEMBER = 'payment/SET_SHOW_VERIFY_ACCOUNT_MEMBER';
export const SET_SHOW_VERIFY_ACCOUNT_REMOVAL_MEMBER = 'payment/SET_SHOW_VERIFY_ACCOUNT_REMOVAL_MEMBER';
export const SET_SELECTED_PAYMENT_CHALLENGED_MEMBER_GUID = 'payment/SET_SELECTED_PAYMENT_CHALLENGED_MEMBER_GUID';
export const SET_ADD_PAYPAL = 'payment/SET_ADD_PAYPAL';
export const SET_FETCH_MX_GUID = 'payment/SET_FETCH_MX_GUID';
export const SET_RECENTLY_USED_PAYMENTS = 'payment/SET_RECENTLY_USED_PAYMENTS';
