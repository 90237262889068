import moment, { DurationInputArg1, DurationInputArg2, Moment, MomentInput } from 'moment';

export const dayName = (date: MomentInput) => moment(date).format('dddd');
export const dayLocale = (date: MomentInput) => moment(date).format('LLL');
export const formatDate = (date: MomentInput, format: string) => moment(date).format(format);

export const differenceInDays = (currentDate: MomentInput, comparedTo: MomentInput) => {
  const a = moment(currentDate);
  const b = moment(comparedTo);

  return Math.abs(b.diff(a, 'days'));
};

export const differenceInHours = (date: Date) => {
  const currentUTC = moment.utc();
  const comparedTo = moment(date);

  return currentUTC.diff(comparedTo, 'hours');
};

export const addTime = (duration: DurationInputArg1, type: DurationInputArg2, from?: Moment) => {
  /*
   * y - years
   * Q - quarters
   * M - months
   * w - weeks
   * d - days
   * h - hours
   * m - minutes
   * s - seconds
   * ms - milliseconds
   */
  const addedTime = moment(from).add(duration, type);
  return addedTime;
};

export const differenceInUnitOfTime = (expiryDateTime: Moment, unitOfTime: DurationInputArg2) => {
  /*
   * y - years
   * Q - quarters
   * M - months
   * w - weeks
   * d - days
   * h - hours
   * m - minutes
   * s - seconds
   * ms - milliseconds
   */
  return moment(expiryDateTime).diff(moment(), unitOfTime);
};

export const isBetweenTime = (start: Moment, end: Moment) => {
  const now = new Date();
  return start.isBefore(now) && end.isAfter(now);
};
