import React, { ReactElement, useEffect, useState } from 'react';
import { Toast } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { AlertOptions } from '../../../library/interfaces';
import { dismissAlert } from '../../../store/alerts/actions';

const mapClassNameBySize = {
  lg: {
    root: '',
    header: '',
    body: '',
  },
  sm: {
    root: 'toast-sm',
    header: 'toast-header-sm',
    body: 'toast-body-sm',
  },
};

export default function Alert({ alert }: { alert: AlertOptions }): ReactElement {
  const dispatch = useDispatch();
  const size = alert.size || 'lg';
  const className = mapClassNameBySize[size];

  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
  }, []);

  function removeAlert() {
    setShow(false);
    setTimeout(() => {
      dispatch(dismissAlert(alert.id));
    }, 100);
  }

  return (
    <Toast
      className={className.root}
      show={show}
      autohide={alert.autohide ? alert.autohide : true}
      delay={alert.dismissInMs ? alert.dismissInMs : 8000}
      onClose={removeAlert}
    >
      <Toast.Header closeButton className={`${className.header} toast-header-${alert.level}`}>
        {typeof alert.title === 'string' ? alert.title.toUpperCase() : alert.title}
      </Toast.Header>
      <Toast.Body className={className.body}>{alert.message}</Toast.Body>
    </Toast>
  );
}
