import classNames from 'classnames';
import React from 'react';

interface FormDisclaimerProps {
  title?: string;
  children?: React.ReactNode;
  className?: string;
}
export default function FormDisclaimer({ title, children = title, className }: FormDisclaimerProps) {
  return <div className={classNames('color-black form-disclaimer mb-4', className)}>{children}</div>;
}
