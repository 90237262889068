import React, { ReactElement } from 'react';
import { CFooter } from '@coreui/react';

export default function Footer(): ReactElement {
  return (
    <CFooter fixed={false} className="d-flex justify-content-between">
      <div>
        <a href="https://fluz.app" target="_blank" rel="noopener noreferrer">
          Fluz Fluz LLC
        </a>
        <span className="ml-1">&copy; {new Date().getFullYear()}</span>
      </div>
      <div className="text-center footer-actions d-flex justify-content-between">
        <a href="https://fluz.app/commercial-user-terms/" target="_blank" rel="noopener noreferrer">
          Fluz Commercial Terms of Use
        </a>
        <a href="https://fluz.app/fine-print/" target="_blank" rel="noopener noreferrer">
          Fine Print
        </a>
      </div>
    </CFooter>
  );
}
