import { ActionType } from 'typesafe-actions';

import * as setting from './actions';
import { SET_BREADCRUMB_DISPLAY, SET_SIDEBAR_DISPLAY, SET_SIDEDRAWER_DISPLAY } from './constants';

export type settingAction = ActionType<typeof setting>;

export type SettingState = {
  sidebarDisplay: boolean | string;
  sideDrawerDisplay: boolean | string;
  breadcrumbDisplay: boolean;
};

const defaultState: SettingState = {
  sidebarDisplay: 'responsive',
  sideDrawerDisplay: false,
  breadcrumbDisplay: true,
};

export default (state = defaultState, action: settingAction): SettingState => {
  switch (action.type) {
    case SET_SIDEBAR_DISPLAY:
      return {
        ...state,
        sidebarDisplay: action.payload,
      };
    case SET_SIDEDRAWER_DISPLAY:
      return {
        ...state,
        sideDrawerDisplay: action.payload,
      };
    case SET_BREADCRUMB_DISPLAY:
      return {
        ...state,
        breadcrumbDisplay: action.payload,
      };
    default:
      return state;
  }
};
