import { useDispatch } from 'react-redux';
import { resetAuth } from '../../../store/auth/actions';
import { resetUser } from '../../../store/user/actions';
import { useIntercom } from 'react-use-intercom';

export default function useOnLogout() {
  const dispatch = useDispatch();
  const { shutdown: shutdownIntercom, boot: bootIntercom } = useIntercom();

  function onLogout() {
    try {
      dispatch(resetAuth());
      dispatch(resetUser());
      logoutThirdPartyServices();
    } catch (error) {
      console.error('Error performing onLogout actions!', error);
    }
  }

  function logoutThirdPartyServices() {
    try {
      logoutIntercom();
    } catch (error) {
      console.error('Error logging out of third party services!', error);
      throw error;
    }
  }

  /* 
    shutdownIntercom clears the intercom session for the previously logged in user but also
    closes down Intercom. Intercom must be rebooted so that chat is available on login screen.
    shutdown -> reboot is the Intercom-recommended way to end a session
    More info: https://www.intercom.com/help/en/articles/16845-how-do-i-end-a-session
  */
  function logoutIntercom() {
    try {
      shutdownIntercom();
      bootIntercom();
    } catch (error) {
      console.error('Error logging out of intercom!', error);
      throw error;
    }
  }

  return onLogout;
}
