import { getDeviceAndLocationDetails } from '../../../utils';
const { FLUZ_RSKFD_SHOP_NAME } = process.env;
interface RiskifiedClientDetails {
  cart_id: string | number;
  riskified_cookie: string;
  shop: string;
  shop_id: string;
  page_id: string;
  referrer: string;
  date_string: string;
  document_title: string;
  href: string;
  battery_charging: boolean;
  battery_level: number;
  browser: {
    productsub: string | number;
    env_len: number;
    is_firefox: boolean;
    is_opr: boolean;
  };
  color_depth: number;
  nav_lang: string;
  nav_plu: string;
  os: {
    cpu: string;
    platform: string;
  };
  timestamp: string;
  timezone: string | number;
}

export default function useRiskified() {
  const RISKX = window.RISKX;

  function riskifiedLogPage(pageName: string) {
    RISKX?.go(pageName);
  }

  function getRiskifiedDeviceID() {
    const riskifiedClientDetails: RiskifiedClientDetails = RISKX?.client_info;
    return RISKX?.rResult?.cookieValue || riskifiedClientDetails?.riskified_cookie;
  }

  async function initializeRiskified(): Promise<void> {
    const riskifiedScript = document.createElement('script');
    riskifiedScript.type = 'text/javascript';
    riskifiedScript.async = true;
    riskifiedScript.defer = true;
    getDeviceAndLocationDetails().then((deviceDetails) => {
      riskifiedScript.src = `https://beacon.riskified.com?shop=${FLUZ_RSKFD_SHOP_NAME}&sid=${deviceDetails.deviceId}`;
      document.head.appendChild(riskifiedScript);
    });
  }

  return {
    riskifiedLogPage,
    getRiskifiedDeviceID,
    initializeRiskified,
  };
}

export const RISKIFIED_ACTION = {
  LOGIN: '/account/sign-in',
  LOGOUT: '/account/logout',
  FORGOT_PASSWORD: '/account/forgot-password',
  RESET_PASSWORD: '/account/reset-password',
  DEVICE_VERIFY: '/mfa/new-device/verify',
  PAYMENT_ADDED: '/account/payment-method/create',
  PAYMENT_REMOVED: '/account/payment-method/remove',
  PAYMENT_VERIFY: '/account/payment-method/verify',
  PIN_CODE_CHECK: '/account/pin-code/checked',
  PURCHASE_GC: '/checkout',
  GIFT_CARD_REVEAL: '/account/gift-card/reveal',
  GIFT_CARD_UPDATE: '/account/gift-card/update',
  DEPOSIT_CASH_BALANCE: '/cash-balance/deposit',
  VIRTUAL_CARD_REVEAL: '/account/virtual-card/reveal',
  WITHDRAW_METHOD_REMOVED: '/account/withdraw/removed',
  WITHDRAW_METHOD_ADDED: '/account/withdraw/added',
};

export const RISKIFIED_VIEW = {
  TRANSACTIONS: '/account/transactions',
  GIFT_CARD: (displayId: string) => (displayId ? `/account/transactions/${displayId}` : '/account/transactions'),
  CASH_BALANCE: (displayId: string) => (displayId ? `/account/deposit/${displayId}` : '/deposit/transactions'),
  MONEY_IN: '/account/payment-method/list',
  DASHBOARD: '/dashboard',
  MFA: '/mfa',
  SERVICE_TERMS: '/service-terms',
  VIEW_MERCHANT: (merchantPath?: string) => (merchantPath ? `/merchant/${merchantPath}` : '/merchants'),
  SEARCH_MERCHANT: (searchTerm: string) => (searchTerm ? `/merchant/search?term=${searchTerm}` : '/merchant/search'),
  VIRTUAL_CARD: (displayId: string) =>
    displayId ? `/account/transactions/virtual-cards/${displayId}` : '/account/transactions/virtual-cards',
};
