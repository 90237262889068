import {
  CButton,
  CCreateElement,
  CSidebar,
  CSidebarFooter,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CSidebarNavTitle,
  CSpinner,
} from '@coreui/react';
import classNames from 'classnames';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ArrowLeft from '../../assets/images/misc-icons/arrow-left.png';
import { SignupFormRoutes } from '../../library/enums/SignupForm';
import useLogout from '../../library/hooks/user/useLogout';
import { useSideBar } from '../../navigation/_sidebar';
import useRiskified, { RISKIFIED_ACTION } from '../../services/gateways/riskified/useRiskified';
import useSignupFormService from '../../services/signup-form/useSignupFormService';
import { setSidebarDisplay } from '../../store/setting/actions';
import { SignupFormState } from '../../store/signup-form/reducer';
import { useErrorHandler } from '../../utils';
import { assertIsFetchError } from '../../utils/type_assertions';
import { SIGNUP_FORM_STEPS } from '../../views/KYB/constants';
import { KybStatus } from '../../library/enums';

export default function SideBar(): ReactElement {
  const { sideBar } = useSideBar();
  const dispatch = useDispatch();
  const { logoutUserAndDevice } = useLogout();
  const { riskifiedLogPage } = useRiskified();
  const history = useHistory();
  const { parseResponseError } = useErrorHandler();
  const { getSignupForm } = useSignupFormService();
  const [isLoading, setIsLoading] = useState(false);
  const show = useSelector((state: RootStateOrAny) => state.setting.sidebarDisplay);
  const mounted = useRef<boolean>();
  const pureSideBarValues = [...sideBar];
  const hasExclusiveRate = useSelector((state: RootStateOrAny) => state.user?.user?.account?.has_exclusive_rate);
  const hasBusinessAccount = useSelector((state: RootStateOrAny) => state.user?.user?.has_business_account);
  const signupFormData: SignupFormState = useSelector((state: RootStateOrAny) => state.signupForm);
  const signupFormStatus = signupFormData.kyb_status;

  if (hasExclusiveRate) {
    pureSideBarValues.push({
      _tag: 'CSidebarNavItem',
      name: 'Exclusive rates',
      to: '/exclusive-rates',
    });
  }
  function toggleSidebar() {
    const val: boolean | string = [true, 'responsive'].includes(show) ? false : 'responsive';
    dispatch(setSidebarDisplay(val));
  }

  useEffect(() => {
    mounted.current = true;
    getSignupFormPreviousData();
    return () => {
      mounted.current = false;
    };
  }, []);

  async function logoutUser() {
    try {
      setIsLoading(true);
      riskifiedLogPage(RISKIFIED_ACTION.LOGOUT);
      await logoutUserAndDevice();
    } finally {
      if (mounted.current) {
        setIsLoading(false);
      }
    }
  }

  function handleStartApplication() {
    history.push(SignupFormRoutes.COMPANY_INFO);
  }

  async function getSignupFormPreviousData() {
    try {
      await getSignupForm();
    } catch (error) {
      assertIsFetchError(error);
      const errorDetails = await error.response.json();
      parseResponseError({
        success: error.response.ok,
        statusCode: Number(error.response.status),
        msg: errorDetails.msg,
        error: errorDetails,
      });
    }
  }

  function resolveApplyButtonLabel() {
    switch (signupFormStatus) {
      case KybStatus.IN_PROGRESS:
        return 'Resume application';
      case KybStatus.SUBMITTED_FOR_APPROVAL:
        return 'View application';

      default:
        return 'Start application';
    }
  }

  return (
    <CSidebar
      colorScheme="light"
      show={show}
      className="main-sidebar"
      onShowChange={(val: boolean | string) => dispatch(setSidebarDisplay(val))}
      dropdownMode="openActive"
    >
      <CSidebarNav>
        <CCreateElement
          items={pureSideBarValues}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>

      {!hasBusinessAccount && (
        <div
          className="d-flex px-4 pt-4 gap-3 flex-column border-top border-bottom border-gray_200 justify-content-start"
          style={{ paddingBottom: '40px' }}
        >
          {signupFormStatus === KybStatus.IN_PROGRESS && (
            <div className="badge-completed-steps">
              {signupFormData.completedSteps?.length || 1}/{SIGNUP_FORM_STEPS.length} COMPLETE
            </div>
          )}
          <div>
            <h5 className="h5-heavy color-gray_1000">
              Apply for a Fluz
              <br /> business account
            </h5>
            <p className="color-gray_800">Unlock industrial-scale cashback</p>
          </div>
          <CButton
            type="button"
            style={{ height: 35, width: 'fit-content' }}
            className={classNames(['btn btn-submit fluz-gradient-primary px-4'])}
            onClick={handleStartApplication}
          >
            {resolveApplyButtonLabel()}
          </CButton>
        </div>
      )}

      <div style={{ height: 60, ...(isLoading ? { textAlign: 'center' } : {}), cursor: 'pointer', marginBottom: 32 }}>
        {isLoading ? (
          <CSpinner size="sm" />
        ) : (
          <a className="c-sidebar-nav-link p-regular" onClick={logoutUser}>
            Logout
          </a>
        )}
      </div>
      <CSidebarFooter className="text-right">
        <span className="btn-clear text-right" style={{ cursor: 'pointer' }} onClick={toggleSidebar}>
          <img src={ArrowLeft} />
        </span>
      </CSidebarFooter>
    </CSidebar>
  );
}
