import { CCard, CCardBody, CCardGroup, CCardHeader, CContainer, CForm } from '@coreui/react';
import React from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { z } from 'zod';
import FButton from '../../../components/common/fbutton/FButton';
import CheckboxGroup from '../../../components/inputs/CheckboxGroup';
import FormSection from '../../../components/inputs/FormSection';
import SelectInput from '../../../components/inputs/SelectInput';
import TextInput from '../../../components/inputs/TextInput';
import { BusinessIndustry, BusinessIndustryOptions, KybStatus, PowerPortalUsageOptions } from '../../../library/enums';
import { MainFormValues } from '../../../library/interfaces';
import { SignupFormState } from '../../../store/signup-form/reducer';
import { ERROR_MESSAGES } from '../../../utils/form/error-messages';

export const activityFormSchema = z
  .object({
    nature_of_business: z.string().nullable().optional(),
    website_url: z
      .string()
      .regex(
        /^(https?:\/\/)?(((?:[a-z\d]+(?:-[a-z\d]+)*)\.)+[a-z]{2,63}|(?:\d{1,3}\.){3}\d{1,3}|\[?((?:[0-9a-fA-F]{1,4}(?::[0-9a-fA-F]{1,4})*)?)\]?)(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/,
        ERROR_MESSAGES.invalid.url
      )
      .nullable()
      .optional()
      .or(z.literal('')),
    business_industry: z.object(
      {
        value: z.nativeEnum(BusinessIndustry),
        label: z.string(),
      },
      { required_error: ERROR_MESSAGES.required }
    ),
    power_account_usage: z.string().array().nullable().optional(),
    power_account_usage_other: z.string().nullable().optional(),
  })
  .partial()
  .refine((val) => val?.business_industry?.value !== undefined, {
    message: ERROR_MESSAGES.required,
    path: ['business_industry'],
  })
  .refine(
    (val) =>
      val?.power_account_usage?.includes('OTHERS')
        ? !(
            val?.power_account_usage_other === undefined ||
            val?.power_account_usage_other === null ||
            val?.power_account_usage_other?.length === 0
          )
        : true,
    { message: ERROR_MESSAGES.required, path: ['power_account_usage_other'] }
  );

type ActivityProps = {
  handleSubmitApplication(): void;
};
export default function Activity({ handleSubmitApplication }: ActivityProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<MainFormValues>();
  const history = useHistory();
  const powerAccountUsageValue = useWatch({ name: 'step3.power_account_usage', control });
  const signupFormData: SignupFormState = useSelector((state: RootStateOrAny) => state.signupForm);

  const disableField =
    (signupFormData.kyb_status && signupFormData.kyb_status !== KybStatus.IN_PROGRESS) || signupFormData.loading;

  function handleContinue() {
    if (!disableField) {
      handleSubmitApplication();
    } else {
      history.push('/');
    }
  }

  return (
    <CCardGroup>
      <CCard>
        <CCardHeader className="pl-4 pt-4 pb-2">
          <h2 className="mb-1">Company Activity</h2>
        </CCardHeader>
        <CCardBody className="p-4">
          <CForm className="mt-2 mb-2 w-100">
            <FormSection className="w-100">
              <TextInput
                name="step3.nature_of_business"
                error={errors?.step3?.nature_of_business?.message}
                label="Describe the nature of your business"
                control={control}
                fullWidth
                disabled={disableField}
              />
            </FormSection>
            <FormSection>
              <TextInput
                name="step3.website_url"
                error={errors?.step3?.website_url?.message}
                label="Company website"
                control={control}
                wrapperClassName="flex-grow-1"
                disabled={disableField}
              />
              <SelectInput
                name="step3.business_industry"
                error={errors?.step3?.business_industry?.message}
                label="Industry"
                required
                placeholder="Select general industry."
                wrapperStyle={{
                  minWidth: '40%',
                }}
                options={BusinessIndustryOptions}
                control={control}
                disabled={disableField}
              />
            </FormSection>
            <FormSection>
              <CheckboxGroup
                name="step3.power_account_usage"
                error={errors?.step3?.power_account_usage?.message}
                title="Select what you will use a Fluz power account for. (select all that apply)"
                control={control}
                options={PowerPortalUsageOptions}
                disabled={disableField}
              />
            </FormSection>
            {powerAccountUsageValue?.includes('OTHERS') && (
              <FormSection>
                <TextInput
                  name="step3.power_account_usage_other"
                  error={errors?.step3?.power_account_usage_other?.message}
                  label="Describe your company’s expected use of a Fluz Power account."
                  control={control}
                  required
                  fullWidth
                  disabled={disableField}
                />
              </FormSection>
            )}
            <FButton
              id="traditional"
              onClick={handleContinue}
              className="btn-submit fluz-gradient-primary"
              loading={signupFormData.loading}
            >
              {disableField && !signupFormData.loading ? 'Go to dashboard' : 'Submit your application'}
            </FButton>
          </CForm>
        </CCardBody>
      </CCard>
    </CCardGroup>
  );
}
