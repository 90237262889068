export const cashBalanceDepositStatus = {
  AVAILABLE: 'AVAILABLE',
  FAILED: 'FAILED',
  PENDING: 'PENDING',
  REFUNDED: 'REFUNDED',
  REVERSED: 'REVERSED',
};

export const cashBalanceTransferStatus = {
  AVAILABLE: 'AVAILABLE',
  FAILED: 'FAILED',
  PENDING: 'PENDING',
  REFUNDED: 'REFUNDED',
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS',
  DENIED: 'DENIED',
};

export type CashBalanceDepositStatus = keyof typeof cashBalanceDepositStatus;

export const cashBalanceAvailabilityType = {
  INSTANT: 'INSTANT',
  STANDARD: 'STANDARD',
};

export enum CashBalanceDepositType {
  CASH_BALANCE = 'CASH_BALANCE',
  GIFT_CARD_BALANCE = 'GIFT_CARD_BALANCE',
  RESERVE_BALANCE = 'RESERVE_BALANCE',
}

export type CashBalanceAvailabilityType = keyof typeof cashBalanceAvailabilityType;
