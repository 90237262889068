export enum WithdrawStatus {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  DENIED = 'DENIED',
  FAILED = 'FAILED',
  UNCLAIMED = 'UNCLAIMED',
  REFUNDED = 'REFUNDED',
  ONHOLD = 'ONHOLD',
  BLOCKED = 'BLOCKED',
  RETURNED = 'RETURNED',
  REVERSED = 'REVERSED',
}

export enum WithdrawDisplayStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export enum WithdrawMethods {
  PAYPAL = 'PAYPAL',
  FLUZPAY = 'FLUZPAY',
  PREPAID_VISA = 'PREPAID_VISA',
  PREPAID_MASTER = 'PREPAID_MASTER',
  BANK_ACH = 'BANK_ACH',
  APPLE_PAY = 'APPLE_PAY',
  VENMO = 'VENMO',
  CHARGEBACK_SETTLEMENT = 'CHARGEBACK_SETTLEMENT',
}

export enum WithdrawSource {
  CASH_BALANCE = 'CASH_BALANCE',
  REWARDS_BALANCE = 'REWARDS_BALANCE',
  BONUS_BALANCE = 'BONUS_BALANCE',
  RESERVE_BALANCE = 'RESERVE_BALANCE',
}
