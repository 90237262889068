import {
  CCreateElement,
  CSidebar,
  CSidebarFooter,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CSidebarNavTitle,
} from '@coreui/react';
import classNames from 'classnames';
import React, { ReactElement, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import fluzLogo from '../../assets/images/fluz-icons/fluz-logo-full.svg';
import ArrowLeft from '../../assets/images/misc-icons/arrow-left.svg';
import useOnLogout from '../../library/hooks/user/useOnLogout';
import ProfileCard from '../../views/user/ProfileCard';

export default function Header(): ReactElement {
  const has_developer_access = useSelector((state: RootStateOrAny) => state?.user?.user?.has_developer_access);
  const [isOpen, setIsOpen] = useState(false);
  const onLogout = useOnLogout();
  const { pathname } = useLocation();
  const drawerRoutes: { [key: string]: any }[] = [
    {
      _tag: 'CSidebarNavItem',
      name: 'Account settings',
      to: '/account-settings',
      exact: false,
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Monthly statements',
      to: '/monthly-statements',
      exact: false,
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Downloads',
      to: '/downloads',
      exact: false,
    },
    ...(has_developer_access
      ? [
          {
            _tag: 'CSidebarNavItem',
            name: 'Developer',
            to: '/developer',
            exact: false,
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (pathname && isOpen) setIsOpen(false);
  }, [pathname]);

  function handleOnProfileClick() {
    setIsOpen((prevState) => !prevState);
  }
  function handleCloseDrawer() {
    setIsOpen(false);
  }
  return (
    <>
      <a href="/">
        <img height="29" src={fluzLogo} />
      </a>
      <div
        className={classNames('drawer-wrapper', {
          open: isOpen,
        })}
      >
        <ProfileCard onClick={handleOnProfileClick} />
        <CSidebar
          colorScheme="light"
          show={isOpen}
          className={classNames('drawer')}
          onShowChange={handleOnProfileClick}
          dropdownMode="openActive"
          aside
          size="lg"
          hideOnMobileClick
        >
          <div className="pb-4 pl-4 pr-4" style={{ paddingTop: '4rem' }}>
            <div className="btn-icon" onClick={handleCloseDrawer}>
              <img src={ArrowLeft} />
            </div>
            <ProfileCard className="mt-4" variant="secondary" />
          </div>
          <div className="border-bottom" />
          <CSidebarNav>
            <CCreateElement
              items={drawerRoutes}
              components={{
                CSidebarNavDivider,
                CSidebarNavDropdown,
                CSidebarNavItem,
                CSidebarNavTitle,
              }}
            />
          </CSidebarNav>
          <CSidebarFooter>
            <button className="btn btn-gray-1000" onClick={onLogout}>
              Logout
            </button>
          </CSidebarFooter>
        </CSidebar>
        <div className="backdrop" onClick={handleCloseDrawer} />
      </div>
    </>
  );
}
