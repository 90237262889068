import classNames from 'classnames';
import React, { ReactElement } from 'react';

interface SubHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
}
export default function SubHeader({ title, className, ...props }: SubHeaderProps): ReactElement {
  return (
    <>
      <div className={classNames('d-flex fluz-sub-header', className)} {...props}>
        <p className="p-regular-primary">{title}</p>
      </div>
    </>
  );
}
