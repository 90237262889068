import { cleanRoutesOnProd } from './dev-helper';
import useCampaignAccess from '../library/controllers/campaign-access';

interface Route {
  _tag: string;
  name: string;
  to?: string;
  exact?: boolean;
  _children?: Route[];
}

export function useSideBar() {
  const {
    virtual_cards_enabled,
    cash_balance_deposit_enabled,
    scheduled_tasks_enabled,
    bulk_purchases_enabled,
    gc_prepayment_access_enabled,
  } = useCampaignAccess();
  const sidebarRoutes: { [key: string]: any }[] = [
    {
      _tag: 'CSidebarNavItem',
      name: 'Dashboard',
      to: '/dashboard',
      exact: false,
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Stores',
      to: '/stores',
      exact: false,
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Get a quote',
      to: '/quote',
      exact: false,
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Transactions',
      to: '/transactions',
      exact: false,
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Payment methods',
      to: '/payment-methods',
      exact: false,
    },
  ];

  if (virtual_cards_enabled) {
    sidebarRoutes.splice(2, 0, {
      _tag: 'CSidebarNavItem',
      name: 'Virtual cards',
      to: '/virtual-cards',
      exact: false,
    });
  }

  // Deposit dropdown menu
  const depositRoutes = [];
  if (gc_prepayment_access_enabled) {
    depositRoutes.push({
      _tag: 'CSidebarNavItem',
      name: 'Gift card prepayment',
      to: '/scheduled-gift-card-prepayment',
      exact: true,
    });
  }
  if (cash_balance_deposit_enabled) {
    depositRoutes.push({
      _tag: 'CSidebarNavItem',
      name: 'Cash balance',
      to: '/cash-deposit',
      exact: true,
    });
  }
  if (scheduled_tasks_enabled || bulk_purchases_enabled) {
    depositRoutes.push({
      _tag: 'CSidebarNavItem',
      name: 'Scheduled transactions',
      to: '/scheduled-transactions',
      exact: true,
    });
  }
  if (depositRoutes.length > 0) {
    sidebarRoutes.splice(2, 0, {
      _tag: 'CSidebarNavDropdown',
      name: 'Deposits',
      _children: depositRoutes,
      show: false,
    });
  }

  return { sideBar: cleanRoutesOnProd(sidebarRoutes) };
}
