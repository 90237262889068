import { SignupFormRoutes } from '../../library/enums/SignupForm';

export const SET_SIGNUP_FORM = 'signupForm/SET_SIGNUP_FORM';
export const SET_SIGNUP_FORM_LOADING = 'signupForm/SET_SIGNUP_FORM_LOADING';
export const SET_SIGNUP_FORM_SHOULD_RESET_FIELDS = 'signupForm/SET_SIGNUP_FORM_SHOULD_RESET_FIELDS';
export const SET_SIGNUP_FORM_COMPLETED_STEPS = 'signupForm/SET_SIGNUP_FORM_COMPLETED_STEPS';

export const SIGNUP_FORM_STEPS_TO_ROUTES: string[] = [
  SignupFormRoutes.COMPANY_INFO,
  SignupFormRoutes.COMPANY_OWNERS,
  SignupFormRoutes.COMPANY_ACTIVITY,
];
