import React, { useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { resetAuth, setAccessToken, setRefreshToken } from '../../store/auth/actions';
import { resetUser } from '../../store/user/actions';

interface PrivateRouteProps {
  [x: string]: any;
  children: any;
  path: string;
}

// Adds routes that need to be logged in
const PrivateRoute = ({ children, ...rest }: PrivateRouteProps) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (location.search) {
      const qs = queryString.parse(location.search);
      if (qs.accessToken && qs.refreshToken) {
        dispatch(resetAuth());
        dispatch(resetUser());
        dispatch(setAccessToken(qs.accessToken as string));
        dispatch(setRefreshToken(qs.refreshToken as string));
        history.push('/dashboard');
      }
    }
  });
  const isAuthenticated = useSelector((state: RootStateOrAny) => state.auth.isAuthenticated);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/walkthrough',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
