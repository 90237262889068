import { DeviceDetails } from '../../library/interfaces';
import { ExtendedGetResult, FpjsClient } from '@fingerprintjs/fingerprintjs-pro-spa';

const { FLUZ_FINGERPRINT_API_KEY, FLUZ_FINGERPRINT_ENDPOINT, FLUZ_FINGERPRINT_URL_PATTERN } = process.env;

interface PositionPayload {
  coords: {
    accuracy: number;
    altitude: number | null;
    altitudeAccuracy: number | null;
    heading: number | null;
    latitude: number;
    longitude: number;
    speed: number | null;
  };
  timestamp: number;
}

const fpjsClient = new FpjsClient({
  loadOptions: {
    apiKey: FLUZ_FINGERPRINT_API_KEY,
    scriptUrlPattern: FLUZ_FINGERPRINT_URL_PATTERN,
    endpoint: FLUZ_FINGERPRINT_ENDPOINT,
  },
});

export async function initializeFingerprintClient() {
  return await fpjsClient.init();
}

export async function getDeviceFingerprint() {
  return await fpjsClient.getVisitorData({ extendedResult: true });
}

async function getDeviceLocation() {
  function getUserLocationPromise() {
    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true,
        maximumAge: 300000,
        timeout: 3000,
      });
    });
  }
  const deviceLocation = await getUserLocationPromise()
    .then(async (location) => {
      const castedLocation = location as PositionPayload;
      const coordinates = castedLocation.coords;
      return { long: String(coordinates.longitude), lat: String(coordinates.latitude) };
    })
    .catch(() => null);
  return deviceLocation;
}

async function getDeviceDetails(
  fingerPrintData: ExtendedGetResult,
  fetchLocationData: boolean
): Promise<DeviceDetails> {
  const deviceLocation = await (fetchLocationData ? getDeviceLocation() : undefined);

  return {
    deviceId: fingerPrintData.visitorId,
    interface: 'BROWSER',
    macAddress: '0:0:0:0:0', // TODO: Where do we pull it from?
    os: fingerPrintData.os,
    osVersion: fingerPrintData.osVersion,
    softwareVersion: process.env.APP_VERSION,
    type: 'DESKTOP',
    brand: fingerPrintData.browserName,
    model: fingerPrintData.browserVersion,
    location: deviceLocation ? deviceLocation : undefined,
  };
}

export async function getDeviceAndLocationDetails(fetchLocationData = false) {
  return getDeviceFingerprint().then(async (fingerPrintData) =>
    getDeviceDetails(fingerPrintData, fetchLocationData).then((details) => details)
  );
}
