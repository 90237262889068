import ky from 'ky';
import { RootStateOrAny, useSelector } from 'react-redux';
import { MxMember, MxInstitution } from '../../library/interfaces';
import useCommonRequestOptions from '../useCommonRequestOptions';

export default function useMx() {
  const token = useSelector((state: RootStateOrAny) => state.auth.accessToken);
  const { getCommonRequestOptions } = useCommonRequestOptions();

  async function getMxProxyUrl(mxGuid: string): Promise<{ url: string }> {
    const response: { url: string } = await ky
      .get(`mx/user/proxyUrl/${mxGuid}`, {
        ...getCommonRequestOptions('identity', { token }),
      })
      .json();

    return response;
  }
  async function getMxUserGuid() {
    const {
      data: { mx_guid },
    }: any = await ky
      .get('mx/user/create', {
        ...getCommonRequestOptions('identity', { token }),
      })
      .json();

    return mx_guid;
  }
  async function getMxInstitutions() {
    const response: MxInstitution[] = await ky
      .get('mx/user/institutions', {
        ...getCommonRequestOptions('identity', { token }),
      })
      .json();

    return {
      rows: response,
    };
  }
  async function populateMxUserData(memberGuid: string | null) {
    const response = await ky
      .get(`mx/user/populate/${memberGuid}`, {
        ...getCommonRequestOptions('identity', { token }),
      })
      .json();

    return response;
  }
  async function refreshMxUserData() {
    const { status, bankBalances, verifyMembers }: any = await ky
      .get('mx/user/refresh', {
        ...getCommonRequestOptions('identity', { token }),
      })
      .json();

    return {
      status,
      bankBalances,
      verifyMembers,
    };
  }
  async function refreshFuseUserData() {
    const { status, bankBalances, verifyMembers }: any = await ky
      .get('fuse/user/refresh', {
        ...getCommonRequestOptions('identity', { token }),
      })
      .json();

    return {
      status,
      bankBalances,
      verifyMembers,
    };
  }
  async function updateBankBalances() {
    const response = await ky
      .get('mx/user/balances', {
        ...getCommonRequestOptions('identity', { token }),
      })
      .json();

    return response;
  }
  async function refreshMxUserDataMfa(mfaStatus: string) {
    const data = await ky
      .post('mx/user/refresh/mfa', {
        ...getCommonRequestOptions('identity', { token }),
        json: { mfaStatus },
      })
      .json();

    return data;
  }
  async function identifyMxMember(memberGuid: string): Promise<{ status: string; members: MxMember[] }> {
    const data: { status: string; members: MxMember[] } = await ky
      .get(`mx/user/identify/${memberGuid}`, {
        ...getCommonRequestOptions('identity', { token }),
      })
      .json();

    return data;
  }
  async function deleteMxInstitution(bankAuthId: string) {
    const data: { success: boolean } = await ky
      .delete(`mx/institution/${bankAuthId}`, {
        ...getCommonRequestOptions('identity', { token }),
      })
      .json();

    return data;
  }
  async function reenableMxInstitution(bankAuthId: string) {
    const data: { success: boolean } = await ky
      .put(`mx/institution/${bankAuthId}/enable`, {
        ...getCommonRequestOptions('identity', { token }),
      })
      .json();

    return data;
  }
  return {
    getMxProxyUrl,
    getMxUserGuid,
    getMxInstitutions,
    populateMxUserData,
    refreshMxUserData,
    refreshFuseUserData,
    updateBankBalances,
    refreshMxUserDataMfa,
    identifyMxMember,
    deleteMxInstitution,
    reenableMxInstitution,
  };
}
