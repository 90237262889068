import { ActionType } from 'typesafe-actions';
import * as virtualCard from './actions';
import {
  TransactionListItem,
  UserVirtualCard,
  UserVirtualCardResponse,
  VirtualCardMerchantOfferResponse,
} from '../../library/interfaces';
import {
  SET_SELECTED_CARD_INFO,
  SET_SELECTED_CARD_TRANSACTIONS,
  SET_SELECTED_TRANSACTION,
  SET_VIRTUAL_CARD_OFFER,
  SET_VIRTUAL_CARDS_LIST,
  SET_VIRTUAL_CARDS_ITEM_OPTIMISTIC_UPDATE,
} from './constants';
import { MultiUseCardStatus, VirtualCardStatus } from '../../library/enums';

export type VirtualCardAction = ActionType<typeof virtualCard>;

export type VirtualCardState = {
  virtualCardOffers: VirtualCardMerchantOfferResponse;
  currentCardInfo: null | Record<any, any>;
  currentTransactionInfo: null | TransactionListItem;
  virtualCardsList: UserVirtualCardResponse | null;
  virtualCardsListFilters: Record<string, any> | null;
};

const defaultState: VirtualCardState = {
  virtualCardOffers: {} as VirtualCardMerchantOfferResponse,
  currentCardInfo: {
    payload: {},
    cloPayload: {},
    totalUserCloSpending: 0,
    accountBalance: 0,
    openToTransactionType: null,
  },
  currentTransactionInfo: null,
  virtualCardsList: null,
  virtualCardsListFilters: null,
};

export default (state = defaultState, action: VirtualCardAction): VirtualCardState => {
  switch (action.type) {
    case SET_VIRTUAL_CARD_OFFER:
      return {
        ...state,
        virtualCardOffers: action.payload,
      };
    case SET_SELECTED_CARD_INFO: {
      return {
        ...state,
        currentCardInfo: {
          ...action.payload,
        },
      };
    }
    case SET_SELECTED_CARD_TRANSACTIONS: {
      return {
        ...state,
        currentCardInfo: {
          ...state.currentCardInfo,
          transactions: action.payload,
        },
      };
    }
    case SET_SELECTED_TRANSACTION: {
      return {
        ...state,
        currentTransactionInfo: action.payload,
      };
    }
    case SET_VIRTUAL_CARDS_LIST: {
      return {
        ...state,
        virtualCardsList: action.payload.virtualCards,
        virtualCardsListFilters: action.payload.filters,
      };
    }
    case SET_VIRTUAL_CARDS_ITEM_OPTIMISTIC_UPDATE: {
      if (!state.virtualCardsList) return state;

      const virtualCardList = state.virtualCardsList.rows.map((item) => {
        if (item.id === action.payload.virtualCard.id) {
          const updatedItem = {
            ...item,
            ...action.payload.virtualCard,
          };
          if (action.payload.removeIfOutOfFiltering) {
            if (
              state.virtualCardsListFilters?.virtual_card_status === 'Active' &&
              updatedItem.status_type === MultiUseCardStatus.LOCKED
            ) {
              return null;
            }
            if (
              state.virtualCardsListFilters?.virtual_card_status === 'Inactive' &&
              updatedItem.status_type === MultiUseCardStatus.BRAND_TIED
            ) {
              return null;
            }
            if (
              state.virtualCardsListFilters?.virtual_card_status === 'Inactive' &&
              (updatedItem.status_type === MultiUseCardStatus.READY_FOR_USE ||
                updatedItem.status_type === VirtualCardStatus.ACTIVE)
            ) {
              return null;
            }
          }
          return updatedItem;
        }
        return item;
      });

      return {
        ...state,
        virtualCardsList: {
          ...state.virtualCardsList,
          rows: virtualCardList.filter((item) => item) as UserVirtualCard[],
        },
      };
    }
    default:
      return state;
  }
};
