import ky from 'ky';
import useCommonRequestOptions from './useCommonRequestOptions';

export default function useClientLogger() {
  const { getCommonRequestOptions } = useCommonRequestOptions();

  async function log(message: string, error: Error) {
    try {
      if (message || error) {
        const options = getCommonRequestOptions('client', { useBasicAuth: true });
        const headers = options.headers as { [key: string]: string };
        headers['x-fluz-logger'] = 'true';
        await ky.post('logger', { ...options, json: { message, error: error.toString() } });
      }
    } catch (error) {
      console.log('Client logger error!');
    }
  }

  return { log };
}
