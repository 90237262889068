import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import './App.css';
import AlertOverlayContainer from './components/common/notifications/AlertOverlayContainer';
import PrivateRoute from './components/layouts/PrivateRoute';
import SwitchTransition from './components/layouts/SwitchTransition';
import routes from './navigation/_public_routes';
import './scss/style.scss';
import store, { persistor } from './store';
import MainStack from './views/MainStack';
import AppInitialize from './views/AppInitialize';
import { IntercomProvider } from 'react-use-intercom';
import { initializeFingerprintClient } from './utils';
const { FLUZ_INTERCOM_APP_ID } = process.env;

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

function App() {
  useEffect(() => {
    async function initApp() {
      await initializeFingerprintClient();
    }
    initApp();
  }, []);

  return (
    <Provider store={store}>
      <IntercomProvider appId={FLUZ_INTERCOM_APP_ID as string} autoBoot>
        <AlertOverlayContainer />
        <PersistGate persistor={persistor} loading={loading}>
          <Router>
            <Suspense fallback={loading}>
              <SwitchTransition>
                {routes.map((route, idx) => {
                  return (
                    !!route.component && (
                      <Route key={idx} path={route.path}>
                        <route.component />
                      </Route>
                    )
                  );
                })}
                <PrivateRoute path="/" name="Home">
                  <AppInitialize />
                  <MainStack />
                </PrivateRoute>
              </SwitchTransition>
            </Suspense>
          </Router>
        </PersistGate>
      </IntercomProvider>
    </Provider>
  );
}

export default App;
