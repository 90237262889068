export enum ChargebackType {
  USER_DISPUTE = 'USER_DISPUTE',
  PROCESSING_ERROR = 'PROCESSING_ERROR',
}

export enum ChargebackPortal {
  ACCESSMYIQ = 'ACCESSMYIQ',
  AUTHORIZE_NET = 'AUTHORIZE_NET',
  BRAINTREE = 'BRAINTREE',
  ELAVON = 'ELAVON',
  FLUZ = 'FLUZ',
  PAY_PAL = 'PAY_PAL',
}
