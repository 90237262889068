import { decode } from 'jsonwebtoken';

export function getTokenExpiresIn(token: string, timeUnit: 's' | 'ms'): number {
  const decodedToken = decode(token) as Record<string, unknown>;
  const currentTime = new Date().getTime();
  const expirationTime =
    decodedToken && decodedToken.exp ? new Date((decodedToken.exp as number) * 1000).getTime() : undefined;
  const expiresIn = expirationTime ? expirationTime - currentTime : 0;
  return expiresIn ? (timeUnit === 's' ? expiresIn / 1000 : expiresIn) : 0;
}
