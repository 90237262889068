/* eslint-disable no-case-declarations */
import { ActionType } from 'typesafe-actions';

import { BusinessSignupForm } from '../../library/interfaces';
import * as signupActions from './actions';
import {
  SET_SIGNUP_FORM,
  SET_SIGNUP_FORM_COMPLETED_STEPS,
  SET_SIGNUP_FORM_LOADING,
  SET_SIGNUP_FORM_SHOULD_RESET_FIELDS,
} from './constants';
import { SignupFormRoutes } from '../../library/enums/SignupForm';

export type SignupFormAction = ActionType<typeof signupActions>;

export interface SignupFormState extends Partial<BusinessSignupForm> {
  loading: boolean;
  shouldResetField: boolean;
  completedSteps?: SignupFormRoutes[];
}

const defaultState: SignupFormState = {
  loading: false,
  shouldResetField: false,
};

export default (state = defaultState, action: SignupFormAction): SignupFormState => {
  switch (action.type) {
    case SET_SIGNUP_FORM:
      return {
        ...state,
        shouldResetField: action.payload.shouldResetField,
        ...action.payload.data,
      };
    case SET_SIGNUP_FORM_SHOULD_RESET_FIELDS:
      return {
        ...state,
        shouldResetField: action.payload,
      };

    case SET_SIGNUP_FORM_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case SET_SIGNUP_FORM_COMPLETED_STEPS:
      return {
        ...state,
        completedSteps: action.payload,
      };
    default:
      return state;
  }
};
