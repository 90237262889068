import { action } from 'typesafe-actions';
import {
  VirtualCardMerchantOfferResponse,
  Offer,
  TransactionListItem,
  UserVirtualCard,
  UserVirtualCardResponse,
  VirtualCardProgramLimitsResponse,
} from '../../library/interfaces';
import {
  SET_SELECTED_CARD_INFO,
  SET_SELECTED_CARD_TRANSACTIONS,
  SET_SELECTED_TRANSACTION,
  SET_VIRTUAL_CARD_OFFER,
  SET_VIRTUAL_CARDS_LIST,
  SET_VIRTUAL_CARDS_ITEM_OPTIMISTIC_UPDATE,
} from './constants';

export const setSelectedCardInfo = (cardInfo: Record<any, any> | null) => action(SET_SELECTED_CARD_INFO, cardInfo);
export const setSelectedCardTransactions = (transactionInfo: Record<any, any>) =>
  action(SET_SELECTED_CARD_TRANSACTIONS, transactionInfo);
export const setVirtualCardOffers = (virtualCardOffers: VirtualCardMerchantOfferResponse) =>
  action(SET_VIRTUAL_CARD_OFFER, virtualCardOffers);
export const setSelectedTransaction = (transactionInfo: TransactionListItem | null) =>
  action(SET_SELECTED_TRANSACTION, transactionInfo);
export const setVirtualCardsList = (virtualCards: UserVirtualCardResponse, filters: any) =>
  action(SET_VIRTUAL_CARDS_LIST, { virtualCards, filters });
export const setVirtualCardsItemOptimisticUpdate = (
  virtualCard: PartialAndOneRequired<UserVirtualCard, 'id'>,
  removeIfOutOfFiltering?: boolean
) => action(SET_VIRTUAL_CARDS_ITEM_OPTIMISTIC_UPDATE, { virtualCard, removeIfOutOfFiltering });

type PartialAndOneRequired<T, K extends keyof T> = Partial<T> & Pick<T, K>;
