import { ActionType } from 'typesafe-actions';
import { LoadBalancingDefaultSettings } from '../../library/interfaces';

import * as loadBalancingSettings from './actions';
import { SET_DEFAULT_BIN_OVERAGE } from './constants';

export type LoadBalancingSettingsAction = ActionType<typeof loadBalancingSettings>;

export type LoadBalancingSettingsState = {
  default: LoadBalancingDefaultSettings;
};

const defaultState: LoadBalancingSettingsState = {
  default: {
    bin_overage: 0.01,
  },
};

export default (state = defaultState, action: LoadBalancingSettingsAction): LoadBalancingSettingsState => {
  switch (action.type) {
    case SET_DEFAULT_BIN_OVERAGE:
      return {
        ...state,
        default: {
          ...state.default,
          bin_overage: action.payload,
        },
      };
    default:
      return state;
  }
};
