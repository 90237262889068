export enum ExclusiveRateTierStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  LOCKED = 'LOCKED',
  COMPLETED = 'COMPLETED',
}

export enum ExclusiveRateStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
}

export enum ExclusiveRateTransactionStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum ERTransactionStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  LOCKED = 'LOCKED',
}
