import React from 'react';
import challengeIcon from '../../assets/images/misc-icons/person-shield.svg';

interface VerificationProps {
  style?: any;
}

export default function VerificationNeeded(props: VerificationProps) {
  return (
    <span
      style={{
        color: '#ffa918',
        border: '1px solid #ffa918',
        borderRadius: '3px',
        fontSize: '14px',
        padding: '2px 5px',
        marginLeft: '10px',
        textTransform: 'uppercase',
        backgroundColor: 'white',
        ...props.style,
      }}
    >
      <img
        src={challengeIcon}
        style={{
          verticalAlign: 'top',
          paddingTop: '4px',
          paddingRight: '3px',
        }}
      />
      Verification Needed
    </span>
  );
}
