import { SignupFormRoutes } from '../../library/enums/SignupForm';
import { Step1FormValues, Step2FormValues } from '../../library/interfaces';

export const COMPANY_STRUCTURE_SOLE_PROPIETORSHIP = 'soleProprietorship';
export const SIGNUP_FORM_STEPS = [
  {
    route: SignupFormRoutes.COMPANY_INFO,
    description: 'Company information',
  },
  {
    route: SignupFormRoutes.COMPANY_OWNERS,
    description: 'Company owners',
  },
  {
    route: SignupFormRoutes.COMPANY_ACTIVITY,
    description: 'Company activity',
  },
];

export const emptyOwner: Step2FormValues['owners'][0] = {
  first_name: '',
  last_name: '',
  email_address: '',
  title: '',
  ownership_percentage: '',
  owner_since: '',
};

export const emptyOwnerFormValues: Step2FormValues = {
  owners: [emptyOwner],
};
