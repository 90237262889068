import React, { useEffect, useState, useRef } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { CModalBody, CModalFooter, CModalHeader, CSpinner } from '@coreui/react';
import * as widgetSdk from '@mxenabled/web-widget-sdk';
import debounce from 'lodash/debounce';

import useClientLogger from '../../services/useClientLogger';
import { MxInstitution } from '../../library/interfaces';
import { createAlert } from '../../store/alerts/actions';
import useMx from '../../services/payment/useMx';
import Modal from '../Modal';
import useLastUsedPayment from '../../library/hooks/payment-methods/useLastUsedPayment';
import {
  setMxGuid,
  setMxBankBalance,
  setMxVerifyMembers,
  setShowNewBankAccountModal,
  setShowVerifyAccountModal,
  setVerifyAccountRemovalMemberGuid,
  setVerifyAccountMember,
  setMxInstitutions,
  setRecentlyUsedPayment,
} from '../../store/payment/actions';

export default function MxWrapper() {
  const dispatch = useDispatch();
  const { log } = useClientLogger();
  const addNewRef = useRef<any>(null);

  const {
    getMxInstitutions,
    getMxProxyUrl,
    identifyMxMember,
    getMxUserGuid,
    refreshMxUserData,
    refreshFuseUserData,
    refreshMxUserDataMfa,
    updateBankBalances,
    populateMxUserData,
    deleteMxInstitution,
    reenableMxInstitution,
  } = useMx();

  const {
    showNewBankAccountModal,
    showVerifyAccountModal,
    verifyAccountMember,
    verifyAccountRemovalMemberGuid,
    mxInstitutions,
    shouldFetchMXGuid,
  } = useSelector((state: RootStateOrAny) => state.payment);

  const [addNewBankLoading, setAddNewBankLoading] = useState(true);
  const [newlyConnectedMember, setNewlyConnectedMember] = useState(false);
  const [loading, setLoading] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [removedAccountName, setRemovedAccountName] = useState('');
  const [removeAccountGuid, setRemoveAccountGuid] = useState('');
  const [selectedInstitutionGuid, setSelectedInstitutionGuid] = useState('');
  const [proxyUrl, setProxyUrl] = useState('');

  const [mxVerifyStatus, setMxVerifyStatus] = useState(-1);
  const [mxRecentlyConnectedMembers, addMxRecentlyConnectedMembers] = useState<string[]>([]);
  const [showVerifyAccountRemovalModal, setShowVerifyAccountRemovalModal] = useState(false);

  const wrappedSelectedInstitution = debounce((institution) => selectedInstitution(institution), 300);
  const onAddBankAccountSuccess = debounce(() => bankAccountSuccess(), 300);
  const showBankAccountSuccessBanner = debounce(() => bankAccountSuccessBanner(), 300);
  const { fetchLastUsedPaymentMethods } = useLastUsedPayment(true);

  let connectWidget: any;

  useEffect(() => {
    fullRefresh();
    updateBankBalances().then(() => {
      getMxInstitutions().then((institutions) => {
        dispatch(setMxInstitutions(institutions.rows));
      });
    });
  }, []);

  useEffect(() => {
    if (!shouldFetchMXGuid) {
      return;
    }
    async function fetchMxPaymentInfo() {
      try {
        const guid = await getMxUserGuid();
        if (guid) {
          dispatch(setMxGuid(guid));
          getMxProxyUrl(guid).then(({ url: proxy }) => {
            setProxyUrl(proxy);
            dispatch(setShowNewBankAccountModal(true));
          });
          fullRefresh();
          updateBankBalances().then(() => {
            getMxInstitutions().then((institutions) => {
              dispatch(setMxInstitutions(institutions.rows));
            });
          });
        }
      } catch (err: any) {
        log('MxWrapper::Error fetching MX Payment Info', err);
      }
    }
    fetchMxPaymentInfo();
  }, [shouldFetchMXGuid]);

  useEffect(() => {
    if (showNewBankAccountModal) {
      setupConnectWidget();
    }
  }, [showNewBankAccountModal]);

  useEffect(() => {
    if (showVerifyAccountModal) {
      setupConnectWidget();
    }
  }, [showVerifyAccountModal]);

  // Listen for sync status changes
  useEffect(() => {
    if (mxVerifyStatus === 200 && mxRecentlyConnectedMembers.length) {
      populateRecentlyConnected();
    }
  }, [mxVerifyStatus]);

  // Listen for recently connected members
  useEffect(() => {
    if (mxVerifyStatus === 200 && mxRecentlyConnectedMembers.length) {
      populateRecentlyConnected();
    }
  }, [mxRecentlyConnectedMembers]);

  // When user selects an institution, check if it's one they have already connected.
  // Prevent multiple of the same connection.
  useEffect(() => {
    if (selectedInstitutionGuid) {
      const [institution]: MxInstitution[] = mxInstitutions?.filter(
        (institution: MxInstitution) => institution.code === selectedInstitutionGuid
      );
      if (institution) {
        wrappedSelectedInstitution(institution);
      }
    }
  }, [selectedInstitutionGuid]);

  useEffect(() => {
    if (mxInstitutions.length && newlyConnectedMember && !removedAccountName) {
      fetchLastUsedPaymentMethods();
      onAddBankAccountSuccess();
    }
  }, [mxInstitutions]);

  // Display account removal confirmation modal
  useEffect(() => {
    if (verifyAccountRemovalMemberGuid) {
      const [institution]: MxInstitution[] = mxInstitutions?.filter(
        (institution: MxInstitution) => institution.memberGuid === verifyAccountRemovalMemberGuid
      );
      setRemovedAccountName(institution.name);
      setRemoveAccountGuid(institution.bankAuthId);
      setShowVerifyAccountRemovalModal(true);
    }
  }, [verifyAccountRemovalMemberGuid]);

  function bankAccountSuccessBanner() {
    dispatch(
      createAlert({
        title: 'NEW PAYMENT METHOD ADDED',
        message: 'Your account has been added.',
        level: 'success',
      })
    );
  }

  function selectedInstitution(institution: MxInstitution) {
    connectOnClosed();
    deleteOnClosed();
    reenableMxInstitution(institution.bankAuthId).then(({ success }) => {
      if (!success) {
        return dispatch(
          createAlert({
            title: 'Error Encountered',
            message: 'Your account has not been added. Please try again.',
            level: 'danger',
          })
        );
      }

      fullRefresh();
      updateBankBalances().then(() => {
        getMxInstitutions().then((institutions) => {
          dispatch(setMxInstitutions(institutions.rows));
          showBankAccountSuccessBanner();
        });
      });
    });
  }

  function resetLocalState() {
    connectWidget?.unmount();
    setSelectedInstitutionGuid('');
    setAddNewBankLoading(false);
  }

  function connectOnClosed() {
    dispatch(setShowNewBankAccountModal(false));
    dispatch(setShowVerifyAccountModal(false));
    dispatch(setVerifyAccountMember(''));
    resetLocalState();
    // Wait for the next js execution to complete before continuing
    setTimeout(() => {
      if (addNewRef?.current) {
        addNewRef.current.innerHTML = '';
      }
    }, 0);
  }

  function deleteOnClosed() {
    dispatch(setVerifyAccountRemovalMemberGuid(''));
    setShowVerifyAccountRemovalModal(false);
    setRemovedAccountName('');
    setRemoveAccountGuid('');
    resetLocalState();
  }

  function loadingOnClosed() {
    setLoading(false);
  }

  function fullRefresh() {
    refreshFuseUserData().then((userData) => {
      if (userData?.bankBalances && userData?.bankBalances?.length) {
        dispatch(setMxBankBalance(userData.bankBalances));
      }
    });
    refreshMxUserData().then((userData) => {
      if (userData?.bankBalances && userData?.bankBalances?.length) {
        dispatch(setMxBankBalance(userData.bankBalances));
      }
      if (userData?.verifyMembers && userData?.verifyMembers?.length) {
        dispatch(setMxVerifyMembers(userData?.verifyMembers));
      }
      setMxVerifyStatus(userData?.status);
    });
  }

  async function memberConnected(payload: { member_guid: string }) {
    const memberGuid = payload.member_guid;
    const data: any = await identifyMxMember(memberGuid);
    const status = data[memberGuid] === 'CONNECTED' ? 200 : 202;
    if (status === 200) {
      dispatch(setMxVerifyMembers([]));
      addMxRecentlyConnectedMembers([memberGuid]);
      setMxVerifyStatus(status);
    } else {
      dispatch(setMxVerifyMembers([{ memberId: memberGuid }]));
      addMxRecentlyConnectedMembers([memberGuid]);
      setMxVerifyStatus(status);
    }
  }

  // Basic connection widget
  function setupConnectWidget() {
    connectWidget?.unmount();
    connectWidget = undefined;
    setAddNewBankLoading(true);
    connectWidget = new widgetSdk.ConnectWidget({
      proxy: proxyUrl,
      container: '.widget-target-modal',
      includeTransactions: true,
      waitForFullAggregation: false,
      currentMemberGuid: verifyAccountMember?.memberGuid || undefined,
      onLoaded: (payload: any) => {
        setAddNewBankLoading(false);
      },
      onSelectedInstitution: (payload: any) => {
        setSelectedInstitutionGuid(payload.code);
      },
      onSubmitMFA: (payload: any) => {
        refreshMxUserDataMfa('PASS');
      },
      onMemberConnected: connectMemberConnected,
    });
  }

  function connectMemberConnected(payload: any) {
    dispatch(setVerifyAccountMember(''));
    memberConnected(payload);
    setNewlyConnectedMember(true);
    wrappedSetLoading(true);
    connectOnClosed();
  }

  function wrappedSetLoading(bool: boolean) {
    setLoading(false);
    if (!Object.keys(verifyAccountMember || {}).length && !showVerifyAccountModal) {
      setLoading(bool);
    }
  }

  // User confirmed institution removal
  async function removeAccount() {
    if (!removing) {
      setRemoving(true);
      const bankAuthId = removeAccountGuid;
      const response = await deleteMxInstitution(bankAuthId);
      if (!response.success) {
        dispatch(
          createAlert({
            title: 'PAYMENT METHOD WAS NOT ABLE TO BE REMOVED',
            message: 'We encountered an error trying to remove this account. Please try again',
            level: 'danger',
          })
        );
        return;
      }
      const institutions = await getMxInstitutions();
      dispatch(setMxInstitutions(institutions.rows));
      dispatch(setRecentlyUsedPayment([]));
      connectOnClosed();
      deleteOnClosed();
      fullRefresh();
      setRemoving(false);
    }
  }

  async function populateRecentlyConnected() {
    await Promise.all(mxRecentlyConnectedMembers.map(async (memberGuid: string) => populateMxUserData(memberGuid)));

    addMxRecentlyConnectedMembers([]);
    updateBankBalances().then((bankBalances: any) => {
      getMxInstitutions().then((institutions) => {
        setLoading(false);
        dispatch(setMxInstitutions(institutions.rows));
      });
    });
  }

  function bankAccountSuccess() {
    setNewlyConnectedMember(false);
    connectOnClosed();
    showBankAccountSuccessBanner();
  }

  return (
    <div>
      {/* Connect Modal */}
      <Modal size="lg" show={showNewBankAccountModal || showVerifyAccountModal} onClose={connectOnClosed}>
        <CModalHeader closeButton className="card-title-left">
          Add Account
        </CModalHeader>
        <div className="widget-target-modal" ref={addNewRef} style={{ height: '600px' }}>
          {addNewBankLoading && <CModalBody>Loading...</CModalBody>}
        </div>
      </Modal>
      {/* Confirm Delete Modal */}
      <Modal size="lg" show={showVerifyAccountRemovalModal} onClose={deleteOnClosed}>
        <CModalHeader closeButton className="card-title-left">
          Remove Account
        </CModalHeader>
        <CModalBody>
          <div>Are you sure you want to remove {removedAccountName}?</div>
        </CModalBody>
        <CModalFooter className="d-flex">
          <div className={`btn btn-primary ${removing ? 'disabled' : ''}`} onClick={removeAccount}>
            {removing ? 'Removing...' : 'Remove'}
          </div>
        </CModalFooter>
      </Modal>
      {/* Loading Modal */}
      <Modal size="lg" show={loading} onClose={loadingOnClosed}>
        <CModalHeader closeButton className="card-title-left">
          Loading account details
        </CModalHeader>
        <CModalBody>
          <p>Please wait a moment while we fetch your details...</p>
          <div className="text-center">
            <CSpinner color="primary" />
          </div>
        </CModalBody>
      </Modal>
    </div>
  );
}
