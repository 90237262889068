import { CCard, CCardBody, CCardGroup, CCardHeader, CContainer, CForm } from '@coreui/react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import FButton from '../../../components/common/fbutton/FButton';
import Checkbox from '../../../components/inputs/Checkbox';
import FormSection from '../../../components/inputs/FormSection';
import SelectInput from '../../../components/inputs/SelectInput';
import TextInput from '../../../components/inputs/TextInput';
import { BusinessStructure, BusinessStructureOptions, KybStatus } from '../../../library/enums';
import { SignupFormRoutes } from '../../../library/enums/SignupForm';
import { MainFormValues } from '../../../library/interfaces';
import { setSignupForm } from '../../../store/signup-form/actions';
import { SignupFormState } from '../../../store/signup-form/reducer';
import { ERROR_MESSAGES } from '../../../utils/form/error-messages';

export const informationSchema = z
  .object({
    business_name: z.string({ required_error: ERROR_MESSAGES.required }).min(1, ERROR_MESSAGES.required),
    dbaCheckbox: z.boolean().nullable().optional(),
    dba_name: z.string().nullable().optional(),
    business_structure: z.object(
      {
        value: z.nativeEnum(BusinessStructure),
        label: z.string(),
      },
      { required_error: ERROR_MESSAGES.required }
    ),
    street_address_line_1: z.string({ required_error: ERROR_MESSAGES.required }).min(1, ERROR_MESSAGES.required),
    street_address_line_2: z.string().nullable().optional(),
    postal_code: z.string({ required_error: ERROR_MESSAGES.required }).min(1, ERROR_MESSAGES.required),
    city: z.string({ required_error: ERROR_MESSAGES.required }).min(1, ERROR_MESSAGES.required),
    state: z.string({ required_error: ERROR_MESSAGES.required }).min(1, ERROR_MESSAGES.required),
    proprietorshipInput: z.any().nullable().optional(),
    tax_id: z.string().nullable().optional(),
  })
  .partial()
  .refine((val) => val?.business_structure?.value !== undefined, {
    message: ERROR_MESSAGES.required,
    path: ['business_structure'],
  })
  .refine(
    (val) => !(val?.business_structure?.value === BusinessStructure.SOLE_PROPRIETORSHIP && !val?.proprietorshipInput),
    {
      message: ERROR_MESSAGES.required,
      path: ['proprietorshipInput'],
    }
  )
  .refine(
    (val) => !(val?.business_structure?.value !== BusinessStructure.SOLE_PROPRIETORSHIP && !val?.tax_id?.length),
    {
      message: ERROR_MESSAGES.required,
      path: ['tax_id'],
    }
  )
  .refine((val) => !(val?.dbaCheckbox && val?.dba_name?.length === 0), {
    message: ERROR_MESSAGES.required,
    path: ['dba_name'],
  });

export default function Information() {
  const history = useHistory();
  const {
    control,
    formState: { errors },
    trigger,
    setValue,
    getValues,
  } = useFormContext<MainFormValues>();
  const dbaCheckboxValue = useWatch({ name: 'step1.dbaCheckbox', control });
  const companyStructureValue = useWatch({ name: 'step1.business_structure', control });
  const { dirtyFields } = useFormState({ control });
  const dispatch = useDispatch();
  const signupFormData: SignupFormState = useSelector((state: RootStateOrAny) => state.signupForm);

  const isSoleProprietorship = companyStructureValue?.value === BusinessStructure.SOLE_PROPRIETORSHIP;
  const disableField =
    (signupFormData.kyb_status && signupFormData.kyb_status !== KybStatus.IN_PROGRESS) || signupFormData.loading;

  useEffect(() => {
    if (!isSoleProprietorship && dirtyFields.step1?.proprietorshipInput) {
      setValue('step1.proprietorshipInput', undefined);
      const step1 = getValues('step1');
      dispatch(
        setSignupForm({
          step1: {
            ...step1,
            proprietorshipDoc: undefined,
            proprietorshipInput: undefined,
          },
        })
      );
    }
  }, [companyStructureValue]);

  async function handleContinue() {
    if (!disableField) {
      const isStep1Valid = await trigger('step1');
      if (isStep1Valid) {
        history.push(SignupFormRoutes.COMPANY_OWNERS);
      }
    } else {
      history.push(SignupFormRoutes.COMPANY_OWNERS);
    }
  }

  return (
    <div className="mb-5">
      <CCardGroup>
        <CCard>
          <CCardHeader className="pl-4 pt-4 pb-2">
            <h2 className="mb-1">Company Information</h2>
          </CCardHeader>
          <CCardBody className="p-4">
            <CForm className="mt-2 mb-2 w-100">
              <FormSection>
                <TextInput
                  label="Company name"
                  name="step1.business_name"
                  control={control}
                  error={errors?.step1?.business_name?.message}
                  required
                  fullWidth
                  disabled={disableField}
                />
                {dbaCheckboxValue && (
                  <TextInput
                    label="“Doing Business As” name"
                    name="step1.dba_name"
                    control={control}
                    error={errors?.step1?.dba_name?.message}
                    fullWidth
                    disabled={disableField}
                  />
                )}
              </FormSection>
              <FormSection>
                <Checkbox
                  name="step1.dbaCheckbox"
                  label="Check if your company has a DBA (Doing Business As) Name"
                  control={control}
                  required
                  error={errors?.step1?.dbaCheckbox?.message}
                  disabled={disableField}
                />
              </FormSection>
              <FormSection>
                <SelectInput
                  name="step1.business_structure"
                  label="Company structure"
                  required
                  placeholder="Select company structure."
                  fullWidth
                  options={BusinessStructureOptions}
                  control={control}
                  error={errors?.step1?.business_structure?.message}
                  disabled={disableField}
                />
              </FormSection>
              {isSoleProprietorship && (
                <FormSection>
                  <TextInput
                    label="Sole Proprietorship Documentation"
                    name="step1.proprietorshipInput"
                    control={control}
                    type="file"
                    error={errors?.step1?.proprietorshipInput?.message as string}
                    fileInputOptions={{
                      accept: {
                        'image/jpeg': [],
                        'image/png': [],
                        'application/pdf': [],
                      },
                    }}
                    required
                    fullWidth
                    disabled={disableField}
                  />
                </FormSection>
              )}
              <FormSection>
                <TextInput
                  label="Tax ID / EIN Number"
                  name="step1.tax_id"
                  control={control}
                  error={errors?.step1?.tax_id?.message}
                  required={!isSoleProprietorship}
                  fullWidth
                  disabled={disableField}
                />
              </FormSection>
              <FormSection title="Business address">
                <TextInput
                  placeholder="Street address line 1"
                  name="step1.street_address_line_1"
                  control={control}
                  error={errors?.step1?.street_address_line_1?.message}
                  required
                  fullWidth
                  disabled={disableField}
                />
                <TextInput
                  placeholder="Street address line 2"
                  name="step1.street_address_line_2"
                  control={control}
                  error={errors?.step1?.street_address_line_2?.message}
                  required
                  fullWidth
                  disabled={disableField}
                />
                <TextInput
                  placeholder="ZIP"
                  name="step1.postal_code"
                  control={control}
                  error={errors?.step1?.postal_code?.message}
                  required
                  fullWidth
                  disabled={disableField}
                />
              </FormSection>
              <FormSection>
                <TextInput
                  placeholder="City"
                  name="step1.city"
                  control={control}
                  error={errors?.step1?.city?.message}
                  required
                  fullWidth
                  disabled={disableField}
                />
                <TextInput
                  placeholder="State / Region"
                  name="step1.state"
                  control={control}
                  error={errors?.step1?.state?.message}
                  required
                  fullWidth
                  disabled={disableField}
                />
              </FormSection>

              <FButton
                id="traditional"
                onClick={handleContinue}
                className="btn-submit fluz-gradient-primary"
                loading={signupFormData.loading}
              >
                Continue
              </FButton>
            </CForm>
          </CCardBody>
        </CCard>
      </CCardGroup>
    </div>
  );
}
