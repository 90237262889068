import { CButton, CButtonClose, CSpinner } from '@coreui/react';
import React, { ButtonHTMLAttributes, useLayoutEffect, useRef, useState } from 'react';

interface FButtonProps extends CButton {
  title?: string;
  loading?: boolean;
}
export default function FButton({
  loading = false,
  disabled = loading,
  title,
  children = title,
  ...props
}: FButtonProps) {
  const ref = useRef<HTMLButtonElement>(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref?.current?.clientWidth || 0);
    setHeight(ref?.current?.clientHeight || 0);
  }, []);

  return (
    <div>
      {loading && (
        <div
          style={{ position: 'absolute', width, height }}
          className="d-flex justify-content-center align-items-center"
        >
          <div
            className="fbutton-loading-backdrop"
            style={{
              width,
              height,
            }}
          />
          <CSpinner size="sm" style={{ zIndex: 1, color: 'white', opacity: 0.8 }} />
        </div>
      )}
      <CButton className="btn-submit" disabled={disabled} innerRef={ref} {...props}>
        {children}
      </CButton>
    </div>
  );
}
