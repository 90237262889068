import ky from 'ky';
import { RootStateOrAny, useSelector } from 'react-redux';
import useCommonRequestOptions from '../../useCommonRequestOptions';
import useClientLogger from '../../useClientLogger';
import { assertIsError } from '../../../utils/type_assertions';

export default function useIntercomIdentityVerification() {
  const token = useSelector((state: RootStateOrAny) => state.auth.accessToken);
  const { getCommonRequestOptions } = useCommonRequestOptions();
  const { log } = useClientLogger();

  async function getIdentityVerificationHash() {
    try {
      const { identityVerificationHash } = await ky
        .get('identity-hash', { ...getCommonRequestOptions('intercom', { token }) })
        .json();
      if (!identityVerificationHash) {
        throw new Error('Missing identityVerificationHash in getIdentityVerificationHash response!');
      }
      return identityVerificationHash;
    } catch (error) {
      assertIsError(error);
      log('Error getting Intercom Identity Verification hash!', error);
      throw error;
    }
  }

  return {
    getIdentityVerificationHash,
  };
}
