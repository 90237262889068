import { CFormGroup, CInputCheckbox, CInputGroup, CInvalidFeedback, CLabel } from '@coreui/react';
import classNames from 'classnames';
import { snakeCase } from 'lodash';
import React from 'react';
import { Control, useController, useFormState } from 'react-hook-form';

interface CheckboxProps extends CInputCheckbox {
  control: Control<any, any>;
  name: string;
  title?: string;
  id?: string;
  required?: boolean;
  error?: string;
  wrapperClassName?: string;
  wrapperStyle?: React.CSSProperties;
  fullWidth?: boolean;
}
export default function Checkbox({
  control,
  name,
  id = snakeCase(name),
  title,
  label,
  required,
  error,
  wrapperClassName,
  wrapperStyle,
  disabled,
  defaultValue,
  fullWidth = false,
}: CheckboxProps) {
  const { defaultValues } = useFormState({ control, name });
  const {
    field: { ref, ...field },
  } = useController({
    name,
    control,
    defaultValue: defaultValue || defaultValues?.[name],
  });

  const RequiredText = <span className="color-danger font-weight-bold"> *</span>;

  return (
    <div
      className={classNames(
        'd-flex flex-column mb-3',
        {
          'w-100': fullWidth,
        },
        wrapperClassName
      )}
      style={wrapperStyle}
    >
      {!!id && !!title && (
        <CLabel htmlFor={id} className="input-label mb-2">
          <>
            {title}
            {required && RequiredText}
          </>
        </CLabel>
      )}
      <CInputGroup htmlFor={id} className="d-block">
        {!!error && <CInvalidFeedback className="always-show-error  mb-3">{error}</CInvalidFeedback>}

        <CFormGroup variant="custom-checkbox">
          <CInputCheckbox
            custom
            id={`checkBox-${id}`}
            checked={field.value}
            disabled={disabled}
            innerRef={ref}
            {...field}
          />
          <CLabel variant="custom-checkbox" htmlFor={`checkBox-${id}`} className="gray-checkbox">
            <p className="p-extra-small">{label}</p>
          </CLabel>
        </CFormGroup>
      </CInputGroup>
    </div>
  );
}
