import ky from 'ky';
import qs from 'query-string';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
  BankAccountFilters,
  BankAccountResponse,
  BankCardFilters,
  BankCardResponse,
  PaginatedQuery,
  PayPalFilters,
  PayPalResponse,
  UserVerification,
  PaymentMethodRates,
  RestrictedPaymentMethod,
} from '../../library/interfaces';
import { PaymentMethodRatesTransactionType } from '../../library/enums';
import useCommonRequestOptions from '../useCommonRequestOptions';
import { setPreferredPaymentMethods } from '../../store/user/actions';

interface PreferredPaymentMethod {
  payment_preference_id: string; // PK
  user_id: string; // FK
  payment_method_type: string;
  payment_method_id: string;
  payment_method_role: string;
  system_created?: boolean;
}

export default function usePayment() {
  const token = useSelector((state: RootStateOrAny) => state.auth.accessToken);
  const { getCommonRequestOptions } = useCommonRequestOptions();

  async function getBankCardVerification(bankCardId: string): Promise<UserVerification> {
    return ky
      .get('bank-cards/verification/' + bankCardId, {
        ...getCommonRequestOptions('payment', { token }),
      })
      .json();
  }

  async function requestBankCardVerification(bankCardId: string): Promise<{ verificationId: string }> {
    return ky
      .post('bank-cards/verification/request', {
        ...getCommonRequestOptions('payment', { token }),
        json: { bankCardId },
      })
      .json();
  }

  async function verifyBankCardVerification(
    bankCardId: string,
    verificationAmount: number
  ): Promise<{ verified: boolean; remainingAttempts?: number }> {
    return ky
      .post('bank-cards/verification/verify', {
        ...getCommonRequestOptions('payment', { token }),
        json: { bankCardId, verificationAmount },
      })
      .json();
  }

  async function removeBankCardSingle(bankCardId: string): Promise<{ success: boolean }> {
    return ky
      .post('bank-cards/remove', {
        ...getCommonRequestOptions('payment', { token }),
        json: { bankCardId },
      })
      .json();
  }

  async function removeBankCardList(bankCardIds: string[]): Promise<{ success: boolean }> {
    return ky
      .post('bank-cards/remove', {
        ...getCommonRequestOptions('payment', { token }),
        json: { bankCardIds },
      })
      .json();
  }

  async function getBankCards(query: PaginatedQuery & BankCardFilters): Promise<BankCardResponse> {
    return ky
      .get('bank-cards?' + qs.stringify(query as Record<string, any>), {
        ...getCommonRequestOptions('payment', { token }),
      })
      .json();
  }

  async function getBankAccounts(query: PaginatedQuery & BankAccountFilters): Promise<BankAccountResponse> {
    return ky
      .get('bank-accounts?' + qs.stringify(query as Record<string, any>), {
        ...getCommonRequestOptions('payment', { token }),
      })
      .json();
  }

  async function getPayPals(query: PaginatedQuery & PayPalFilters): Promise<PayPalResponse> {
    return ky
      .get('paypals?' + qs.stringify(query as Record<string, any>), {
        ...getCommonRequestOptions('payment', { token }),
      })
      .json();
  }

  async function updatePreferredBackupPaymentMethod(bankCardId: string): Promise<any> {
    const methods: PreferredPaymentMethod[] = await ky
      .post('preferred-payment-methods/backup', {
        ...getCommonRequestOptions('payment', { token }),
        json: { bankCardId },
      })
      .json();

    if (methods) {
      setPreferredPaymentMethods(methods);
    }
    return methods;
  }

  async function getPreferredPaymentMethods(): Promise<PreferredPaymentMethod[]> {
    return ky
      .get('preferred-payment-methods', {
        ...getCommonRequestOptions('payment', { token }),
      })
      .json();
  }

  async function getLastUsedPayments(page: number, limit: number, fba = 0, fp = 0): Promise<any> {
    return ky
      .get(`last-used?${qs.stringify({ page, limit, fba, fp })}`, {
        ...getCommonRequestOptions('payment', { token }),
      })
      .json();
  }

  async function getPaymentMethodRates(
    transactionType: PaymentMethodRatesTransactionType
  ): Promise<PaymentMethodRates[]> {
    return ky.get(`dynamic-rates/${transactionType}`, { ...getCommonRequestOptions('payment', { token }) }).json();
  }

  async function getRestrictedPaymentMethodsList(): Promise<{
    hasRestrictedPaymentMethods: boolean;
    restrictedPaymentMethods: RestrictedPaymentMethod[];
  }> {
    return ky.get('restricted-payment-methods', { ...getCommonRequestOptions('payment', { token }) }).json();
  }
  return {
    getBankCardVerification,
    requestBankCardVerification,
    verifyBankCardVerification,
    getBankCards,
    getLastUsedPayments,
    getBankAccounts,
    getPayPals,
    getPaymentMethodRates,
    getRestrictedPaymentMethodsList,
    removeBankCardSingle,
    removeBankCardList,
    updatePreferredBackupPaymentMethod,
    getPreferredPaymentMethods,
  };
}
