import { CContainer, CFade } from '@coreui/react';
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Breadcrumb from '../components/layouts/Breadcrumb';
import Footer from '../components/layouts/Footer';
import Header from '../components/layouts/Header';
import SideBar from '../components/layouts/SideBar';
import { MxWrapper } from '../components/mx-accounts';
import { usePrivateRoutes } from '../navigation/_private_routes';

const MainStack = () => {
  const { privateRoutes } = usePrivateRoutes();

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse" />
    </div>
  );

  return (
    /* Main Header */

    <div className="c-app c-default-layout">
      <div className="d-flex fluz-header">
        <Header />
      </div>
      <SideBar />
      <div className="c-wrapper">
        <Breadcrumb />
        <div className="c-body">
          <main className="c-main">
            {/* Main Inner container */}
            <CContainer fluid className="px-3">
              <Suspense fallback={loading}>
                {/* Main authenticated router */}
                <Switch>
                  {privateRoutes.map((route, idx) => {
                    return (
                      route.component && (
                        <Route key={route.key || idx} path={route.path} exact={route.exact}>
                          <CFade>
                            <route.component />
                          </CFade>
                        </Route>
                      )
                    );
                  })}
                  {/* Fallback route */}
                  <Redirect from="/" to="/dashboard" />
                </Switch>
              </Suspense>
            </CContainer>
          </main>
        </div>
        <Footer />
      </div>

      <MxWrapper />
    </div>
  );
};

export default MainStack;
