import { CCol, CRow, CContainer } from '@coreui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useHistory, useRouteMatch } from 'react-router-dom';
import { z } from 'zod';
import SubHeader from '../../components/layouts/SubHeader';
import SwitchTransition from '../../components/layouts/SwitchTransition';
import { SignupFormRoutes } from '../../library/enums/SignupForm';
import { MainFormValues, Step3FormValues } from '../../library/interfaces';
import useSignupFormService from '../../services/signup-form/useSignupFormService';
import { createAlert } from '../../store/alerts/actions';
import { setBreadcrumbDisplay, setSidebarDisplay } from '../../store/setting/actions';
import { setSignupFormLoading } from '../../store/signup-form/actions';
import { SignupFormState } from '../../store/signup-form/reducer';
import { useErrorHandler } from '../../utils';
import { assertIsFetchError } from '../../utils/type_assertions';
import { SetupProcess } from './components';
import Activity, { activityFormSchema } from './components/Activity';
import Information, { informationSchema } from './components/Information';
import Owners, { ownersSchema } from './components/Owners';
import { emptyOwner } from './constants';

export const mainFormSchema = z.object({
  step1: informationSchema.optional(),
  step2: ownersSchema.nullable().optional(),
  step3: activityFormSchema.nullable().optional(),
});

export default function AccountApplication() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { parseResponseError } = useErrorHandler();
  const dispatch = useDispatch();
  const { updateSignupForm, submitApplication } = useSignupFormService();
  const signupFormData: SignupFormState = useSelector((state: RootStateOrAny) => state.signupForm);
  const { user } = useSelector((state: RootStateOrAny) => state.user);
  const defaultValues: MainFormValues = {
    step1:
      {
        ...signupFormData.step1,
        proprietorshipInput: signupFormData.step1?.proprietorshipInput,
      } || {},
    step2: signupFormData.step2 || {
      owners: [
        { ...emptyOwner, first_name: user.first_name, last_name: user.last_name, email_address: user.email_address },
      ],
    },
    step3: signupFormData.step3 || {},
  };
  const mainFormMethods = useForm({ resolver: zodResolver(mainFormSchema), defaultValues, mode: 'all' });
  const { trigger, getValues } = mainFormMethods;

  async function handleSubmitApplication() {
    try {
      const isWholeFormValid = await trigger(['step1', 'step2', 'step3']);

      if (isWholeFormValid) {
        dispatch(setSignupFormLoading(true));
        const step3 = getValues('step3') as Step3FormValues;
        await updateSignupForm({ step3 });
        await submitApplication();

        dispatch(
          createAlert({
            title: 'ACCOUNT APPLICATION SUBMITTED',
            message: 'Your account was submitted and is under review.',
            level: 'success',
          })
        );
        history.push('/');
      } else {
        dispatch(
          createAlert({
            title: 'ACCOUNT APPLICATION FORM',
            message:
              'There is some errors or missing fields on the form, please fill all required fields and try again.',
            level: 'warning',
          })
        );
      }
    } catch (error) {
      assertIsFetchError(error);
      const errorDetails = await error.response.json();
      parseResponseError({
        success: error.response.ok,
        statusCode: Number(error.response.status),
        msg: errorDetails.msg,
        error: errorDetails,
      });
    } finally {
      dispatch(setSignupFormLoading(false));
    }
  }

  useEffect(() => {
    dispatch(setSidebarDisplay(false));
    dispatch(setBreadcrumbDisplay(false));

    return () => {
      dispatch(setSidebarDisplay(true));
      dispatch(setBreadcrumbDisplay(true));
    };
  }, []);

  useEffect(() => {
    if ((signupFormData.step1 || signupFormData.step2 || signupFormData.step3) && !mainFormMethods.formState.isDirty) {
      mainFormMethods.reset(defaultValues, { keepDirty: true, keepErrors: true });
    }
  }, [signupFormData]);

  useEffect(() => {
    if (signupFormData.step1 && mainFormMethods.formState.dirtyFields.step1) {
      mainFormMethods.setValue('step1', defaultValues.step1);
    }
    if (signupFormData.step2 && mainFormMethods.formState.dirtyFields.step2) {
      mainFormMethods.setValue('step2', defaultValues.step2);
    }
    if (signupFormData.step3 && mainFormMethods.formState.dirtyFields.step3) {
      mainFormMethods.setValue('step3', defaultValues.step3);
    }
  }, [signupFormData.step1, signupFormData.step2, signupFormData.step3]);

  return (
    <FormProvider {...mainFormMethods}>
      <CContainer fluid>
        <SubHeader title={'Fluz account application'} style={{ left: 0 }} />

        <CRow className="container-default-margins">
          <CCol md="3">
            <SetupProcess handleSubmitApplication={handleSubmitApplication} />
          </CCol>

          <CCol md="9">
            <SwitchTransition disableDefaultBehaviour>
              <Route exact path={`${SignupFormRoutes.COMPANY_INFO}`} render={() => <Information />} />
              <Route exact path={`${SignupFormRoutes.COMPANY_OWNERS}`} render={() => <Owners />} />
              <Route
                exact
                path={`${SignupFormRoutes.COMPANY_ACTIVITY}`}
                render={() => <Activity handleSubmitApplication={handleSubmitApplication} />}
              />
              <Redirect from={`${path}`} to={`${SignupFormRoutes.COMPANY_INFO}`} />
            </SwitchTransition>
          </CCol>
        </CRow>
      </CContainer>
    </FormProvider>
  );
}
