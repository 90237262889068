import { useCallback, useEffect, useState } from 'react';
import { FieldPath, FieldValues, useController, useFormContext } from 'react-hook-form';

export const useInputState = <T extends FieldValues>(name: FieldPath<T>) => {
  const [isValid, setValid] = useState(false);

  const { control } = useFormContext();

  const { field, formState } = useController({
    name,
    control,
  });

  useEffect(
    useCallback(() => {
      (async () => {
        try {
          const { errors } = await control._executeSchema([name]);

          setValid(!errors[name]);
        } catch (err) {
          console.log(err);
        }
      })();
    }, [field.value])
  );

  return {
    field,
    formState,
    control,
    isValid,
  };
};
