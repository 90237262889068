import React from 'react';

const Login = React.lazy(() => import('../views/auth/Login'));
const OAuthCallback = React.lazy(() => import('../components/auth/OAuthCallback'));
const ForgotPassword = React.lazy(() => import('../views/auth/password/ForgotPassword'));
const MFA = React.lazy(() => import('../views/auth/MFA'));
const ChangePassword = React.lazy(() => import('../views/auth/password/ChangePassword'));
const EnterpriseServiceTerms = React.lazy(() => import('../views/auth/service-terms/EnterpriseServiceTerms'));
const Walkthrough = React.lazy(() => import('../views/auth/Walkthrough'));

const routes = [
  { path: '/walkthrough', name: 'Walkthrough', component: Walkthrough },
  { path: '/legacy-login', name: 'Login', component: Login },
  { path: '/callback', name: 'MFA', component: OAuthCallback },
  { path: '/forgot-password', name: 'ForgotPassword', component: ForgotPassword },
  { path: '/mfa', name: 'MFA', component: MFA },
  { path: '/service-terms', name: 'ServiceTerms', component: EnterpriseServiceTerms },
  { path: '/change-password', name: 'ChangePassword', component: ChangePassword },
];

export default routes;
