import { CampaignName } from '../enums';
import { RootStateOrAny, useSelector } from 'react-redux';

export default function useCampaignAccess() {
  const campaignsAccess = useSelector((state: RootStateOrAny) => state.user.campaignsAccess);

  const campaignFeatures: { [key: string]: boolean } = {};
  Object.keys(CampaignName).forEach((campaign) => {
    const currCampaign = campaignsAccess[campaign];
    const key = `${campaign.toLowerCase()}_enabled`;
    const featureEnabled =
      currCampaign?.current_phase === 'RELEASED' ||
      (currCampaign?.current_phase === 'ALPHA' && currCampaign?.user_access_level === 'ALPHA') ||
      (currCampaign?.current_phase === 'BETA' &&
        (currCampaign?.user_access_level !== 'NONE' ||
          (currCampaign?.has_waitlist && currCampaign?.is_access_granted)));

    campaignFeatures[key] = featureEnabled;
  });

  return campaignFeatures;
}
