import BankIcon from '../../assets/images/payment-providers/BankIcon.svg';
import PayPalIcon from '../../assets/images/payment-providers/PayPal.svg';
import { getBankCardLogo } from '../../library/controllers/bank-card';
import { BankAccount, BankCard, FormattedPaymentOption, PayPal } from '../../library/interfaces';
import ApplePay from '../../assets/images/payment-providers/ApplePay.svg';
import GooglePay from '../../assets/images/payment-providers/GooglePay.svg';

export const paymentMethodsMap: {
  [x: string]: {
    accountDetailKey?: string;
    name?: string;
    balance?: string;
    icon?: string;
    id?: string;
  };
} = {
  BANK_ACCOUNT: {
    accountDetailKey: 'bank_account',
  },
  BANK_CARD: {
    accountDetailKey: 'bank_card',
  },
  CREDIT_CARD: {
    accountDetailKey: 'bank_card',
  },
  PAYPAL: {
    accountDetailKey: 'paypal',
  },
  GOOGLE_PAY: {
    name: 'Google Pay',
    icon: GooglePay,
    id: 'google',
  },
  APPLE_PAY: {
    name: 'Apple Pay',
    icon: ApplePay,
    id: 'apple',
  },
  FLUZPAY: {
    balance: 'fluzpay_amount',
  },
};

function getBankCardExpiration(
  bankCard: BankCard,
  format: 'MM/YY' | 'MMYY' | 'MM/YYYY' | 'MMYYYY',
  prefixString?: string
) {
  const hasMonth = !!bankCard?.expiration_month;
  const hasYear = !!bankCard?.expiration_year;

  if (bankCard && hasMonth && hasYear) {
    const month = bankCard.expiration_month.length === 1 ? `0${bankCard.expiration_month}` : bankCard.expiration_month;
    const year = bankCard.expiration_year;
    const formattedMonthYear = {
      'MM/YY': `${month}/${String(year).slice(2)}`,
      'MM/YYYY': `${month}/${year}`,
      MMYY: `${month}${String(year).slice(2)}`,
      MMYYYY: `${month}${year}`,
    };
    if (format !== undefined) {
      return prefixString !== undefined ? `${prefixString} ${formattedMonthYear[format]}` : formattedMonthYear[format];
    } else {
      return prefixString !== undefined
        ? `${prefixString} ${formattedMonthYear['MM/YYYY']}`
        : formattedMonthYear['MM/YYYY'];
    }
  } else {
    return '';
  }
}

export function formatPaymentMethod(
  paymentSource: BankAccount | BankCard | PayPal
): FormattedPaymentOption | undefined {
  const bankAccount = paymentSource as BankAccount;
  const bankCard = paymentSource as BankCard;
  const paypal = paymentSource as PayPal;

  if (bankAccount && bankAccount.bank_account_id !== undefined) {
    const bankName =
      bankAccount.plaid_credentials?.institution_name !== undefined
        ? `${bankAccount.plaid_credentials?.institution_name} -`
        : '';
    const isBankRelink = bankAccount.bank_institution_auth?.auth_status !== 'CONNECTED';
    return {
      id: bankAccount.bank_account_id,
      icon: BankIcon,
      name: `${bankName} ${bankAccount.nickname ?? bankAccount.account_name}`,
      last_four: `****${bankAccount.last_four}`,
      type: 'BANK_ACCOUNT',
      sub_type: bankAccount.type,
      requireBackupPayment: bankAccount.require_backup_payment,
      isDisabled: isBankRelink,
      bank_account_id: bankAccount.bank_account_id,
      bank_institution_auth_id: bankAccount.bank_institution_auth_id,
    };
  } else if (bankCard && bankCard.bank_card_id !== undefined) {
    return {
      id: bankCard.bank_card_id,
      icon: getBankCardLogo(bankCard.card_processer),
      name: bankCard.nickname,
      last_four: `****${bankCard.last_four_digits}`,
      expiration: getBankCardExpiration(bankCard, 'MM/YYYY', ', Exp:'),
      isVerified: true,
      isDisabled: false,
      type: 'BANK_CARD',
      sub_type: bankCard.card_type ? bankCard.card_type : 'CREDIT',
      encrypted_cc_number: bankCard.encrypted_cc_number,
      bank_card_id: bankCard.bank_card_id,
      cash_balance_deposit_fee_percent: bankCard.cash_balance_deposit_fee_percent,
      max_deposit_fee: bankCard.max_deposit_fee,
      min_deposit_fee: bankCard.min_deposit_fee,
      restrict_transaction_amounts_by_denominations: bankCard.restrict_transaction_amounts_by_denominations,
      availableTransactionAmounts: bankCard.availableTransactionAmounts,
      mccOverrides: bankCard.mccOverrides,
    };
  } else if (paypal && paypal.paypal_vault_id !== undefined) {
    return {
      id: paypal.paypal_vault_id,
      icon: PayPalIcon,
      name: paypal.email,
      type: 'PAYPAL',
      sub_type: 'UNKNOWN',
      isDisabled: false,
      paypal_vault_id: paypal.paypal_vault_id,
    };
  }
}

export function getBankCardBIN(encryptedCCNumber: string) {
  return encryptedCCNumber.slice(0, 6);
}
