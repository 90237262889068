import ky from 'ky';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import useRiskified from '../../services/gateways/riskified/useRiskified';
import {
  PaginatedQuery,
  UserVirtualCardTransactionFilter,
  UserVirtualCardTransactionResponse,
  VirtualCardMerchantOfferResponse,
  CreateVirtualCardPayload,
  VirtualCardResponse,
  VirtualCardRevealedDetails,
  CancelVirtualCardPayload,
  CloPayload,
  UserVirtualCardResponse,
  UserVirtualCardFilter,
  TransactionFilterResponse,
  TransactionFilterPayload,
  VirtualCardProgramLimitsResponse,
  DeviceDetails,
} from '../../library/interfaces';
import { CardLinkedOffersCapType, VirtualCardTransactionListType, VirtualCardVendor } from '../../library/enums';
import useCommonRequestOptions from '../useCommonRequestOptions';
import { getDeviceAndLocationDetails } from '../../utils/device';
import { assertIsFetchError } from '../../utils/type_assertions';
import { useErrorHandler } from '../../utils';
import { setSelectedCardInfo } from '../../store/virtual-card/actions';

export default function useVirtualCard() {
  const dispatch = useDispatch();
  const token = useSelector((state: RootStateOrAny) => state.auth.accessToken);
  const { createMergedToken, getCommonRequestOptions } = useCommonRequestOptions();
  const { riskifiedLogPage } = useRiskified();
  const { parseResponseError } = useErrorHandler();
  let deviceDetails: DeviceDetails;

  const history = useHistory();

  async function createVirtualCard(
    payload: CreateVirtualCardPayload,
    pinAuthToken: string
  ): Promise<VirtualCardResponse> {
    if (!deviceDetails) {
      deviceDetails = await getDeviceAndLocationDetails(true);
    }
    const mergedToken = createMergedToken(pinAuthToken, token);
    return ky
      .post('', {
        ...getCommonRequestOptions('virtual-card', { token: mergedToken }),
        json: { ...payload, deviceDetails },
      })
      .json();
  }

  async function getVirtualCardMerchantOffers(): Promise<VirtualCardMerchantOfferResponse> {
    return ky.get('offers', { ...getCommonRequestOptions('virtual-card', { token }) }).json();
  }

  async function getVirtualCardProgramLimits(payload: {
    virtualCardId: string;
  }): Promise<VirtualCardProgramLimitsResponse> {
    return ky
      .patch('program-limits', {
        ...getCommonRequestOptions('virtual-card', { token }),
        json: payload,
      })
      .json();
  }

  async function cancelVirtualCard(payload: CancelVirtualCardPayload) {
    if (!deviceDetails) {
      deviceDetails = await getDeviceAndLocationDetails(true);
    }
    return ky
      .post('cancel', { ...getCommonRequestOptions('virtual-card', { token }), json: { ...payload, deviceDetails } })
      .json();
  }

  async function getAllVirtualCards(query: PaginatedQuery & UserVirtualCardFilter): Promise<UserVirtualCardResponse> {
    return ky
      .get('virtual-card?' + qs.stringify(query as Record<string, any>), {
        ...getCommonRequestOptions('user', { token }),
      })
      .json();
  }

  async function getAllVirtualCardTransactions(
    query: PaginatedQuery & UserVirtualCardTransactionFilter
  ): Promise<UserVirtualCardTransactionResponse> {
    return ky
      .get('virtual-card/transactions?' + qs.stringify(query as Record<string, any>), {
        ...getCommonRequestOptions('user', { token }),
      })
      .json();
  }

  async function getVirtualCardById(virtualCardId: string): Promise<VirtualCardResponse> {
    return ky
      .get('virtual-card/' + virtualCardId, {
        ...getCommonRequestOptions('user', { token }),
      })
      .json();
  }

  async function getActiveVirtualCard(): Promise<VirtualCardResponse> {
    return ky
      .get('active-virtual-card', {
        ...getCommonRequestOptions('user', { token }),
      })
      .json();
  }

  async function getTransactionsByVirtualCard(
    virtualCardId: string,
    payload: TransactionFilterPayload
  ): Promise<TransactionFilterResponse> {
    return ky
      .post(`virtual-card/${virtualCardId}/transactions`, {
        ...getCommonRequestOptions('user', { token }),
        json: payload,
      })
      .json();
  }

  async function getTotalCardLinkedOfferUserPurchase(
    card_linked_offer_id: string,
    cap_type: CardLinkedOffersCapType
  ): Promise<{ cloTotalUserSpent: number }> {
    return ky
      .get(`card-linked-offer/user-spent/${card_linked_offer_id}?cap_type=${cap_type}`, {
        ...getCommonRequestOptions('user', { token }),
      })
      .json();
  }

  async function getProgramBalance(vendorName: VirtualCardVendor): Promise<any> {
    return ky
      .get(`program-balance/${vendorName}`, {
        ...getCommonRequestOptions('virtual-card', { token }),
      })
      .json();
  }

  async function revealVirtualCardDetails({
    virtualCardId,
    revealToken,
  }: {
    virtualCardId: string;
    revealToken: string;
  }): Promise<VirtualCardRevealedDetails> {
    const { VGS_URL } = process.env;
    const FLUZ_REVEAL_VC_API_URL = `${VGS_URL}/api/v1/virtual-card/reveal`;
    if (!deviceDetails) {
      deviceDetails = await getDeviceAndLocationDetails(true);
    }
    const body = {
      virtualCardId,
      deviceLocation: deviceDetails,
    };
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${revealToken}`,
        'cache-control': 'no-cache',
      },
      body: JSON.stringify(body),
      timeout: 50000,
    };
    return ky.post(FLUZ_REVEAL_VC_API_URL, options).json();
  }

  async function loadVirtualCardDetails(
    virtualCardId: string,
    accountBalance: number,
    cloPayload?: CloPayload | null,
    totalUserCloSpending?: number | null,
    openToTransactionType?: VirtualCardTransactionListType
  ) {
    const payload = await fetchVirtualCardData(virtualCardId);

    const cardLinkedOfferId = cloPayload?.cardLinkedOffer?.card_linked_offer_id;
    const cardLinkedOfferCapType = cloPayload?.cardLinkedOffer?.cap_type;

    if (cardLinkedOfferId && cardLinkedOfferCapType && !totalUserCloSpending) {
      totalUserCloSpending = Number(
        await getTotalCardLinkedOfferUserPurchase(cardLinkedOfferId, cardLinkedOfferCapType)
      );
    }
    dispatch(setSelectedCardInfo({ payload, cloPayload, totalUserCloSpending, accountBalance, openToTransactionType }));
  }

  async function fetchVirtualCardData(virtualCardId: string) {
    try {
      return await getVirtualCardById(virtualCardId);
    } catch (error) {
      assertIsFetchError(error);
      const errorDetails = await error.response.json();
      parseResponseError({
        success: error.response.ok,
        statusCode: Number(error.response.status),
        msg: errorDetails.msg,
        error: errorDetails,
      });
    }
  }

  async function fetchVirtualCardTransactionData(
    virtualCardId: string,
    purchaseId: string,
    transactionType: VirtualCardTransactionListType
  ) {
    try {
      return await getTransactionsByVirtualCard(virtualCardId, {
        transaction_types: [transactionType],
        purchase_id: purchaseId,
      });
    } catch (error) {
      assertIsFetchError(error);
      const errorDetails = await error.response.json();
      parseResponseError({
        success: error.response.ok,
        statusCode: Number(error.response.status),
        msg: errorDetails.msg,
        error: errorDetails,
      });
    }
  }

  return {
    createVirtualCard,
    getVirtualCardMerchantOffers,
    getVirtualCardProgramLimits,
    cancelVirtualCard,
    revealVirtualCardDetails,
    loadVirtualCardDetails,
    getActiveVirtualCard,
    getAllVirtualCards,
    getAllVirtualCardTransactions,
    getVirtualCardById,
    getTotalCardLinkedOfferUserPurchase,
    getTransactionsByVirtualCard,
    getProgramBalance,
  };
}
