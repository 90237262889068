export enum CampaignName {
  CASH_BALANCE_DEPOSIT = 'CASH_BALANCE_DEPOSIT',
  VIRTUAL_CARDS = 'VIRTUAL_CARDS',
  MX_INTEGRATION = 'MX_INTEGRATION',
  SCHEDULED_TASKS = 'SCHEDULED_TASKS',
  BULK_PURCHASES = 'BULK_PURCHASES',
  MULTI_USE_VIRTUAL_CARDS = 'MULTI_USE_VIRTUAL_CARDS',
  I2C_VIRTUAL_CARDS = 'I2C_VIRTUAL_CARDS',
  MARQETA_VIRTUAL_CARDS = 'MARQETA_VIRTUAL_CARDS',
  GC_PREPAYMENT_ACCESS = 'GC_PREPAYMENT_ACCESS',
}
