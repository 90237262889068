import { ActionType } from 'typesafe-actions';

import * as payment from './actions';
import {
  SET_MX_GUID,
  SET_MX_MEMBERS,
  SET_MX_BANK_BALANCES,
  ADD_ACH_METHODS,
  SET_MX_VERIFY_MEMBERS,
  SET_MX_INSTITUTIONS,
  SET_SHOW_NEW_BANK_ACCOUNT_MODAL,
  SET_SHOW_VERIFY_ACCOUNT_MODAL,
  SET_SHOW_VERIFY_ACCOUNT_MEMBER,
  SET_SHOW_VERIFY_ACCOUNT_REMOVAL_MEMBER,
  SET_SELECTED_PAYMENT_CHALLENGED_MEMBER_GUID,
  SET_ADD_PAYPAL,
  SET_FETCH_MX_GUID,
  SET_RECENTLY_USED_PAYMENTS,
} from './constants';

import { MxInstitution, MxMember, BankAccount } from '../../library/interfaces';

export type PaymentAction = ActionType<typeof payment>;

export type PaymentState = {
  mxGuid: string;
  mxInstitutions: MxInstitution[];
  mxMembers: MxMember[];
  mxVerifyMembers: {
    memberId: string;
    bankInstitutionAuthId?: string;
  }[];
  mxBankBalances: BankAccount[];
  mxNewAchAccounts: any;
  mxVerifyStatus: number;
  mxRecentlyConnectedMembers: Array<string>;
  showNewBankAccountModal: boolean;
  showVerifyAccountModal: boolean;
  showVerifyAccountRemovalModal: boolean;
  verifyAccountRemovalMemberGuid: string;
  verifyAccountMember: any | null;
  selectedPaymentChallengedMemberGuid: string;
  paypal: string;
  shouldFetchMXGuid: boolean;
  recentlyUsedPayments: BankAccount[];
};

const defaultState: PaymentState = {
  mxGuid: '',
  mxInstitutions: [] as MxInstitution[],
  mxMembers: [] as MxMember[],
  mxVerifyMembers: [],
  mxBankBalances: [] as BankAccount[],
  mxNewAchAccounts: [],
  mxVerifyStatus: -1,
  mxRecentlyConnectedMembers: [],
  showNewBankAccountModal: false,
  showVerifyAccountModal: false,
  showVerifyAccountRemovalModal: false,
  verifyAccountRemovalMemberGuid: '',
  verifyAccountMember: null,
  selectedPaymentChallengedMemberGuid: '',
  paypal: '',
  shouldFetchMXGuid: false,
  recentlyUsedPayments: [] as BankAccount[],
};

export default (state = defaultState, action: PaymentAction): PaymentState => {
  switch (action.type) {
    case SET_RECENTLY_USED_PAYMENTS:
      return {
        ...state,
        recentlyUsedPayments: action.payload,
      };
    case SET_MX_GUID:
      return {
        ...state,
        mxGuid: action.payload,
      };
    case SET_MX_MEMBERS:
      return {
        ...state,
        mxMembers: action.payload,
      };
    case SET_MX_BANK_BALANCES:
      return {
        ...state,
        mxBankBalances: action.payload,
      };
    case ADD_ACH_METHODS:
      return {
        ...state,
        mxNewAchAccounts: action.payload,
      };
    case SET_MX_VERIFY_MEMBERS:
      return {
        ...state,
        mxVerifyMembers: action.payload,
      };
    case SET_MX_INSTITUTIONS:
      return {
        ...state,
        mxInstitutions: action.payload,
      };
    case SET_SHOW_NEW_BANK_ACCOUNT_MODAL:
      return {
        ...state,
        showNewBankAccountModal: action.payload,
      };
    case SET_FETCH_MX_GUID:
      return {
        ...state,
        shouldFetchMXGuid: action.payload,
      };
    case SET_SHOW_VERIFY_ACCOUNT_MODAL:
      return {
        ...state,
        showVerifyAccountModal: action.payload,
      };
    case SET_SHOW_VERIFY_ACCOUNT_MEMBER:
      return {
        ...state,
        verifyAccountMember: action.payload,
      };
    case SET_SHOW_VERIFY_ACCOUNT_REMOVAL_MEMBER:
      return {
        ...state,
        verifyAccountRemovalMemberGuid: action.payload,
      };
    case SET_ADD_PAYPAL:
      return {
        ...state,
        paypal: action.payload,
      };
    case SET_SELECTED_PAYMENT_CHALLENGED_MEMBER_GUID:
      return {
        ...state,
        selectedPaymentChallengedMemberGuid: action.payload,
      };
    default:
      return state;
  }
};
