import AmericanExpress from '../../assets/images/payment-providers/AmericanExpress.svg';
import UnknownCard from '../../assets/images/payment-providers/CardLogoUnknown.svg';
import DinersClub from '../../assets/images/payment-providers/DinersClub.svg';
import Discover from '../../assets/images/payment-providers/Discover.svg';
import JCB from '../../assets/images/payment-providers/JCB.svg';
import Maestro from '../../assets/images/payment-providers/Maestro.svg';
import Mastercard from '../../assets/images/payment-providers/Mastercard.svg';
import Visa from '../../assets/images/payment-providers/Visa.svg';
import { formatDate } from '../../utils';
import { removeEmpty } from '../../utils/misc';
import { BankCardProcessor } from '../enums';
import { BankCard, BankCardDisplay, BankCardFilters, UserAddress } from '../interfaces';

export const formatAddress = (address: Partial<UserAddress>): string => {
  let formattedAddress = `${address.street_address_line_1}`;

  if (address.street_address_line_2) {
    formattedAddress += `\n${address.street_address_line_2}`;
  }

  if (address.city) {
    formattedAddress += `\n${address.city}`;
    if (address.state) {
      formattedAddress += `, ${address.state}`;
    }
    formattedAddress += ` ${address.postal_code}`;
  }

  return formattedAddress;
};

export const resolveBankCardAddress = (bank_card: BankCard): Partial<UserAddress> => {
  const hasBillingRecord = Boolean(bank_card['billing_address.address_id']);

  return {
    street_address_line_1: hasBillingRecord
      ? bank_card?.['billing_address.street_address_line_1']
      : bank_card.billing_street_line_1,
    street_address_line_2: hasBillingRecord
      ? bank_card?.['billing_address.street_address_line_2']
      : bank_card.billing_street_line_2,
    country: hasBillingRecord ? bank_card?.['billing_address.country'] : bank_card.country,
    city: hasBillingRecord ? bank_card?.['billing_address.city'] : bank_card.billing_city,
    state: hasBillingRecord ? bank_card?.['billing_address.state'] : bank_card.billing_state,
    postal_code: hasBillingRecord
      ? bank_card?.['billing_address.postal_code']
      : bank_card.billing_zip_code || bank_card.billing_postal_code,
  };
};

export const mapBankCardFiltersToQuery = (filters?: Record<string, any>): BankCardFilters | undefined => {
  return filters
    ? removeEmpty({
        card_processer: filters.card_processer?.trim(),
        created_at: filters.created_at?.trim(),
        address: filters.formatted_address?.trim(),
        last_four_digits: filters.last_four_digits?.trim(),
      })
    : undefined;
};

export const mapBankCardToTable = (bankCards: BankCard[]): Partial<BankCardDisplay>[] =>
  bankCards.map((bankCard: BankCard) => {
    const address: Partial<UserAddress> = resolveBankCardAddress(bankCard);
    return {
      bank_card_id: bankCard.bank_card_id,
      card_processer: bankCard.card_processer,
      last_four_digits: '****' + bankCard.last_four_digits,
      exp_date: `${
        bankCard.expiration_month.length > 1 ? bankCard.expiration_month : '0' + bankCard.expiration_month
      }/${bankCard.expiration_year.toString().substring(2)}`,
      created_at: formatDate(bankCard.created_at, 'MM/DD/YY'),
      auth_charge_verified: bankCard.auth_charge_verified,
      address,
      formatted_address: formatAddress(address),
    };
  });

export const getBankCardLogo = (bankCardProcessor: BankCardProcessor) => {
  switch (bankCardProcessor) {
    case 'VISA': {
      return Visa;
    }
    case 'AMERICAN_EXPRESS': {
      return AmericanExpress;
    }
    case 'DISCOVER': {
      return Discover;
    }
    case 'MASTERCARD': {
      return Mastercard;
    }
    case 'JCB': {
      return JCB;
    }
    case 'MAESTRO': {
      return Maestro;
    }
    case 'DINERS': {
      return DinersClub;
    }
    default: {
      return UnknownCard;
    }
  }
};
