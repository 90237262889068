import React, { useState } from 'react';
import { CInvalidFeedback, CLabel } from '@coreui/react';
import Select, { GroupedOptionsType, Props as SelectProps } from 'react-select';
import { Control, Controller, ControllerProps } from 'react-hook-form';
import classNames from 'classnames';

interface SelectInputProps extends Omit<ControllerProps, 'render' | 'name' | 'defaultValue'>, SelectProps {
  control: Control<any, any>;
  name: string;
  id?: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  error?: string;
  className?: string;

  wrapperClassName?: string;
  wrapperStyle?: React.CSSProperties;
  fullWidth?: boolean;
}
export default function SelectInput({
  name,
  id = name,
  label,
  required,
  defaultValue,
  rules,
  control,
  error,
  className,
  fullWidth = false,
  wrapperStyle,
  wrapperClassName,
  disabled,
  ...otherProps
}: SelectInputProps) {
  const RequiredText = <span className="color-danger font-weight-bold"> *</span>;

  return (
    <div
      className={classNames(
        'd-flex flex-column mb-3',
        {
          'w-100': fullWidth,
        },
        wrapperClassName
      )}
      style={wrapperStyle}
    >
      {!!id && !!label && (
        <CLabel htmlFor={id} className="input-label">
          <>
            {label}
            {required && RequiredText}
          </>
        </CLabel>
      )}
      <div
        className={classNames('select-input', {
          'w-100': fullWidth,
        })}
      >
        <Controller
          render={({ field }) => (
            <Select
              className={classNames('fluz-select-container', className)}
              {...field}
              isDisabled={disabled}
              classNamePrefix="fluz-select"
              {...otherProps}
            />
          )}
          control={control}
          name={name}
          defaultValue={defaultValue}
          rules={rules}
        />
        {!!error && <CInvalidFeedback className="always-show-error">{error}</CInvalidFeedback>}
      </div>
    </div>
  );
}
