import { truncateDecimals } from '../number/truncate-decimals';

export const displayCurrency = (val?: string | number, decimals = 2, round = false, currency = 'USD') => {
  if (!val) val = 0;

  const options = {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    round,
  };
  return formatCurrency(val, options);
};

const formatCurrency = (rawVal?: string | number, options?: Intl.NumberFormatOptions & { round?: boolean }) => {
  let val = rawVal;
  if (val !== undefined && !options?.round) {
    val = truncateDecimals(val, options?.maximumFractionDigits); // truncate to prevent NumberFormat's default rounding
  }
  options?.round ? delete options.round : '';
  const currencyFMT = new Intl.NumberFormat('en-US', options);
  const currency = currencyFMT.format(val && typeof Number(val) === 'number' ? Number(val) : 0);
  return currency;
};

export function isInternationalCurrency(currencyCode: string | undefined) {
  if (!currencyCode || currencyCode === '') {
    return false;
  }
  return currencyCode !== 'USD';
}
