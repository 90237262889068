import React from 'react';
import { cleanRoutesOnProd } from './dev-helper';
import useCampaignAccess from '../library/controllers/campaign-access';
import AccountApplication from '../views/KYB/AccountApplication';

const Dashboard = React.lazy(() => import('../views/dashboard/Dashboard'));
const Stores = React.lazy(() => import('../views/merchant/Stores'));
const TransactionsList = React.lazy(() => import('../views/transactions/TransactionsList'));
const Quote = React.lazy(() => import('../views/Quote'));
const PaymentMethods = React.lazy(() => import('../views/payment-methods/PaymentMethods'));
const PurchaseProvider = React.lazy(() => import('../views/purchase/PurchaseProvider'));
const ScheduledPurchaseConfirmation = React.lazy(() => import('../views/purchase/ScheduledPurchaseConfirmation'));
const DepositCash = React.lazy(() => import('../views/cash-balance/DepositCash'));
const ScheduledGiftCardBalance = React.lazy(
  () => import('../views/scheduled-gift-card-balance/ScheduledGiftCardBalance')
);
const ScheduledDepositConfirmation = React.lazy(
  () => import('../views/scheduled-gift-card-balance/ScheduledDepositConfirmation')
);
const ScheduledTransactions = React.lazy(() => import('../views/scheduled-transactions/ScheduledTransactions'));
const DepositConfirmation = React.lazy(() => import('../views/cash-balance/DepositConfirmation'));
const WithdrawConfirmation = React.lazy(() => import('../views/cash-balance/WithdrawConfirmation'));
const ExclusiveRateList = React.lazy(() => import('../views/exclusive-rates/ExclusiveRateList'));
const VirtualCard = React.lazy(() => import('../views/virtual-card/VirtualCard'));
const VirtualCardList = React.lazy(() => import('../views/transactions/past-virtual-cards/VirtualCardList'));
const Settings = React.lazy(() => import('../views/account/settings/Settings'));
const Developer = React.lazy(() => import('../views/user/developer/Developer'));
const Explorer = React.lazy(() => import('../views/user/developer/Explorer'));
const Reference = React.lazy(() => import('../views/user/developer/Reference'));

export function usePrivateRoutes() {
  const { virtual_cards_enabled, gc_prepayment_access_enabled } = useCampaignAccess();
  const routes = [
    { path: '/', exact: true, name: 'Home' },
    { path: '/dashboard', key: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
    { path: '/quote', name: 'Quote', component: Quote },
    { path: '/transactions', exact: true, name: 'Transactions', component: TransactionsList },
    { path: '/transactions/transfers', exact: true, name: 'Transfers', component: TransactionsList },
    { path: '/transactions/transfer/:depositId', exact: true, name: 'Cash Deposit', component: DepositConfirmation },
    { path: '/transactions/withdraw/:withdrawId', exact: true, name: 'Withdraw', component: WithdrawConfirmation },
    { path: '/transactions/repayments', exact: true, name: 'Repayments', component: TransactionsList },
    { path: '/transactions/purchases', exact: true, name: 'Purchases', component: TransactionsList },
    {
      path: '/transactions/declines',
      exact: true,
      name: 'Declines',
      key: '/transactions/declines',
      component: TransactionsList,
    },
    { path: '/transactions/purchase/:purchaseId', exact: true, name: 'Purchase', component: PurchaseProvider },
    {
      path: '/transactions/scheduled-purchase/:scheduledPurchaseId',
      exact: true,
      name: 'Bulk Purchase',
      component: ScheduledPurchaseConfirmation,
    },
    { path: '/stores', exact: true, name: 'Stores', component: Stores },
    { path: '/stores/:merchantId', exact: true, name: 'Offer', component: PurchaseProvider },
    { path: '/payment-methods', exact: true, name: 'Payment methods' },
    // { path: '/payment-methods/cash-deposit', exact: true, name: 'Deposit cash', component: DepositCash }, // TEMP - NEED TO CLEAN UP DEPOSIT ROUTES :)
    { path: '/cash-deposit', exact: true, name: 'Deposit cash', component: DepositCash },
    { path: '/payment-methods/:payment_type?', name: 'Options', component: PaymentMethods },
    { path: '/exclusive-rates', name: 'Exclusive rates', component: ExclusiveRateList },
    {
      path: '/scheduled-transactions',
      exact: true,
      name: 'Scheduled transactions',
      component: ScheduledTransactions,
    },
    {
      path: '/account-settings',
      exact: true,
      name: 'Account settings',
      component: Settings,
    },
    { path: '/kyb', name: 'CreateAccount', component: AccountApplication },
    {
      path: '/developer',
      exact: true,
      name: 'Developer',
      component: Developer,
    },
    {
      path: '/developer/explorer',
      exact: true,
      name: 'Explorer',
      component: Explorer,
    },
    {
      path: '/developer/reference',
      exact: true,
      name: 'API Reference',
      component: Reference,
    },
  ];

  if (virtual_cards_enabled) {
    routes.push(
      {
        path: '/virtual-cards/create',
        key: '/virtual-cards/create',
        exact: true,
        name: 'Create a virtual card',
        component: VirtualCard,
      },
      ...getSideDrawerRoutes('/virtual-cards/create', '/virtual-cards/create', VirtualCard),
      {
        path: '/virtual-cards',
        key: '/virtual-cards',
        exact: true,
        name: 'Virtual cards',
        component: VirtualCardList,
      },
      ...getSideDrawerRoutes('/virtual-cards', '/virtual-cards', VirtualCardList),
      {
        path: '/transactions/virtual-cards',
        key: '/transactions/virtual-cards',
        exact: true,
        name: 'Virtual cards',
        component: TransactionsList,
      },
      ...getSideDrawerRoutes('/transactions/virtual-cards', '/transactions/virtual-cards', TransactionsList),
      ...getSideDrawerRoutes('/transactions/declines', '/transactions/declines', TransactionsList)
    );
  }

  if (gc_prepayment_access_enabled) {
    routes.push(
      {
        path: '/scheduled-gift-card-prepayment',
        exact: true,
        name: 'Gift card prepayment',
        component: ScheduledGiftCardBalance,
      },
      {
        path: '/scheduled-gift-card-prepayment/:scheduledDepositId',
        exact: true,
        name: 'Prepayment schedule',
        component: ScheduledDepositConfirmation,
      }
    );
  }

  return { privateRoutes: cleanRoutesOnProd(routes) };
}

function getSideDrawerRoutes(prefix: string, key: string, Component: any) {
  return [
    {
      path: `${prefix}/:virtualCardId`,
      key,
      exact: true,
      name: 'Details',
      component: Component,
    },
    {
      path: `${prefix}/:virtualCardId/edit`,
      key,
      exact: true,
      name: 'Edit',
      component: Component,
    },
    {
      path: `${prefix}/:virtualCardId/:transactionId`,
      key,
      exact: true,
      name: 'Transaction',
      component: Component,
    },
  ];
}
