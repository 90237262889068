import { action } from 'typesafe-actions';
import { BusinessSignupForm } from '../../library/interfaces';
import {
  SET_SIGNUP_FORM,
  SET_SIGNUP_FORM_COMPLETED_STEPS,
  SET_SIGNUP_FORM_LOADING,
  SET_SIGNUP_FORM_SHOULD_RESET_FIELDS,
} from './constants';
import { SignupFormRoutes } from '../../library/enums/SignupForm';

export const setSignupForm = (data: Partial<BusinessSignupForm>, shouldResetField = false) =>
  action(SET_SIGNUP_FORM, { shouldResetField, data });
export const setSignupFormLoading = (loading: boolean) => action(SET_SIGNUP_FORM_LOADING, loading);
export const setSignupFormShouldResetFields = (shouldReset = false) =>
  action(SET_SIGNUP_FORM_SHOULD_RESET_FIELDS, shouldReset);
export const setSignupFormCompletedSteps = (completedSteps: SignupFormRoutes[] = []) =>
  action(SET_SIGNUP_FORM_COMPLETED_STEPS, completedSteps);
