import useAuth from '../../../services/auth/useAuth';
import useOnLogout from './useOnLogout';
import { getDeviceAndLocationDetails, useErrorHandler } from '../../../utils';
import { assertIsFetchError } from '../../../utils/type_assertions';

export default function useLogout() {
  const { logout } = useAuth();
  const onLogout = useOnLogout();
  const { parseResponseError } = useErrorHandler();

  async function logoutUserAndDevice() {
    try {
      const deviceDetails = await getDeviceAndLocationDetails(false);
      await logout({ deviceId: deviceDetails.deviceId });
      onLogout();
    } catch (error) {
      assertIsFetchError(error);
      const errorDetails = await error.response.json();
      parseResponseError({
        success: error.response.ok,
        statusCode: Number(error.response.status),
        msg: errorDetails.msg,
        error: errorDetails,
      });
    }
  }

  return { logoutUserAndDevice };
}
