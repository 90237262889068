export const INIT_SEATS = 'user/INIT_SEATS';
export const SELECT_SEAT = 'user/SELECT_SEAT';
export const INIT_VOUCHERS = 'user/INIT_VOUCHERS';
export const INIT_USER = 'user/INIT_USER';
export const RESET_USER_STATE = 'user/RESET_USER_STATE';
export const SET_BALANCE = 'user/SET_BALANCE';
export const SET_INITIAL_STATE = 'user/SET_INITIAL_STATE';
export const SET_LIMIT = 'user/SET_LIMIT';
export const SET_CAMPAIGN_PHASED_ACCESS = 'user/SET_CAMPAIGN_PHASED_ACCESS';
export const SET_USER_LOADING = 'user/SET_USER_LOADING';
export const SET_PREFERRED_PAYMENT_METHODS = 'user/SET_PREFERRED_PAYMENT_METHODS';
