import {
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupPrepend,
  CInputGroupText,
  CInvalidFeedback,
  CLabel,
} from '@coreui/react';
import classNames from 'classnames';
import moment from 'moment';
import React, { useCallback } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { Control, Controller, ControllerProps, ControllerRenderProps } from 'react-hook-form';
import InputMask from 'react-input-mask';

type InputType = React.HTMLInputTypeAttribute | 'percentage';

interface Props extends Omit<ControllerProps, 'render' | 'name' | 'defaultValue'>, CInput {
  control: Control<any, any>;
  name: string;
  label?: string;
  btnLabel?: string;
  error?: string;
  mask?: string | (string | RegExp)[];
  fullWidth?: boolean;
  type?: InputType;

  prependContent?: React.ReactNode;
  appendContent?: React.ReactNode;
  wrapperClassName?: string;
  fileInputOptions?: DropzoneOptions;
}

export default function TextInput({
  name,
  id = name,
  label,
  error,
  mask,
  control,
  defaultValue = '',
  rules,
  type,
  fullWidth = false,
  prependContent,
  appendContent,
  wrapperClassName,
  required,
  btnLabel = 'Add file',
  fileInputOptions,
  ...props
}: Props) {
  const RequiredText = <span className="color-danger font-weight-bold"> *</span>;

  const renderPercentageInput = (field: ControllerRenderProps<any, string>) => {
    const { value, onChange, ref, ...otherProps } = field;

    const onChangePercentage = (event: any) => {
      const valueNumber = Number(event.target.value.replace(/\D/g, ''));

      if (valueNumber < 0) return onChange('0');
      if (valueNumber > 100) return onChange('100');
      return onChange(String(valueNumber));
    };

    return (
      <CInputGroup
        className={classNames({
          'w-100': fullWidth,
        })}
      >
        <CInput
          id={id}
          innerRef={ref}
          {...otherProps}
          {...props}
          min={0}
          max={100}
          value={value}
          onChange={onChangePercentage as any}
        />
        <CInputGroupAppend>
          <CInputGroupText className={'text-black'}>%</CInputGroupText>
        </CInputGroupAppend>
      </CInputGroup>
    );
  };

  const renderFileInput = (field: ControllerRenderProps<any, string>) => {
    const { value, onChange, ...otherProps } = field;
    const { disabled } = props;
    const onDrop = useCallback((acceptedFiles) => {
      onChange(acceptedFiles[0]);
    }, []);
    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      maxFiles: 1,
      multiple: false,
      noDrag: false,
      disabled,
      ...fileInputOptions,
    });

    return (
      <CInputGroup
        className={classNames({
          'w-100': fullWidth,
        })}
        {...getRootProps()}
      >
        <input
          id={id}
          style={{ display: 'none' }}
          type="text"
          value={value}
          onChange={onChange}
          readOnly
          {...getInputProps}
        />
        <div className="form-control mr-3" style={{ borderRadius: 3 }}>
          {typeof value === 'string' ? value : value?.name || props.placeholder}
        </div>
        <button onClick={(e) => e.preventDefault()} disabled={disabled} className="btn btn-gray-1000">
          {btnLabel}
        </button>
      </CInputGroup>
    );
  };

  const renderDateInput = (field: ControllerRenderProps<any, string>) => {
    return (
      <CInputGroup
        className={classNames({
          'w-100': fullWidth,
        })}
      >
        {prependContent && <CInputGroupPrepend>{prependContent}</CInputGroupPrepend>}
        <InputMask
          mask="99/99/9999"
          id={id}
          onChange={field.onChange}
          value={moment(field.value).isValid() ? moment(field.value).format('MM/DD/YYYY') : field.value}
          disabled={props.disabled}
        >
          {(inputProps: InputMask) => (
            <input
              ref={field.ref}
              name={name}
              {...inputProps}
              placeholder={props.placeholder}
              disabled={props.disabled}
              className="form-control"
            />
          )}
        </InputMask>
        {appendContent && <CInputGroupAppend>{appendContent}</CInputGroupAppend>}
      </CInputGroup>
    );
  };

  const renderInput = (field: ControllerRenderProps<any, string>) => {
    const { ref, ...otherFieldProps } = field;

    switch (type) {
      case 'percentage':
        return renderPercentageInput(field);
      case 'file':
        return renderFileInput(field);
      case 'date':
        return renderDateInput(field);

      default:
        return (
          <CInputGroup
            className={classNames({
              'w-100': fullWidth,
            })}
          >
            {prependContent && <CInputGroupPrepend>{prependContent}</CInputGroupPrepend>}
            {mask ? (
              <InputMask
                ref={ref}
                mask={mask}
                id={id}
                className="form-control"
                disabled={props.disabled}
                {...otherFieldProps}
              />
            ) : (
              <CInput id={id} innerRef={field.ref} {...otherFieldProps} {...props} disabled={props.disabled} />
            )}
            {appendContent && <CInputGroupAppend>{appendContent}</CInputGroupAppend>}
          </CInputGroup>
        );
    }
  };

  return (
    <div
      className={classNames(
        'd-flex flex-column mb-3',
        {
          'w-100': fullWidth,
        },
        wrapperClassName
      )}
    >
      {!!id && !!label && (
        <CLabel htmlFor={id} className="input-label">
          {label}
          {required && RequiredText}
        </CLabel>
      )}
      <div
        className={classNames({
          'w-100': fullWidth,
        })}
      >
        <React.StrictMode>
          <Controller
            render={({ field }) => renderInput(field)}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={rules}
          />
        </React.StrictMode>
      </div>
      {!!error && <CInvalidFeedback className="always-show-error">{error}</CInvalidFeedback>}
    </div>
  );
}

TextInput.displayName = 'TextInput';
