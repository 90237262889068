import ky from 'ky';
import { RootStateOrAny, useSelector } from 'react-redux';
import { PaymentMethodRatesTransactionType } from '../../library/enums';
import {
  AuthResponse,
  MerchantSpentResponse,
  SpendingLimitResponse,
  User,
  UserProfileResponse,
  WalletEarningsResponse,
  PrepaidReserveBalanceResponse,
  UserReserveBalanceSettingsResponse,
  UserBalanceSettingsResponse,
} from '../../library/interfaces';
import { TransactionLimit, TransactionSpent } from '../../library/interfaces/TransactionLimit';
import useCommonRequestOptions from '../useCommonRequestOptions';
import store from '../../store';

export default function useUser() {
  const token = useSelector((state: RootStateOrAny) => state.auth.accessToken);
  const { getCommonRequestOptions } = useCommonRequestOptions();

  async function getSpendingLimit(): Promise<SpendingLimitResponse> {
    return ky
      .get('spend-history', { ...getCommonRequestOptions('user', { token: store.getState().auth.accessToken }) })
      .json();
  }

  async function getMerchantSpent(merchantId: string): Promise<MerchantSpentResponse> {
    return ky.get(`merchant-spent/${merchantId}`, { ...getCommonRequestOptions('user', { token }) }).json();
  }

  async function getWalletEarnings(): Promise<WalletEarningsResponse> {
    return ky.get('cashback/earnings', { ...getCommonRequestOptions('user', { token }) }).json();
  }

  async function getPrepaidReserveBalance(): Promise<PrepaidReserveBalanceResponse> {
    return ky.get('prepaid-reserve-balance', { ...getCommonRequestOptions('user', { token }) }).json();
  }

  async function getUserReserveBalanceSettings(): Promise<UserReserveBalanceSettingsResponse> {
    return ky.get('reserve-balance-settings', { ...getCommonRequestOptions('user', { token }) }).json();
  }

  async function acceptTermsPrepaidReserveBalance(): Promise<AuthResponse> {
    return ky
      .get('reserve-balance-settings/accept-terms-and-conditions', { ...getCommonRequestOptions('user', { token }) })
      .json();
  }

  async function getUserProfile(): Promise<UserProfileResponse> {
    return ky
      .get('profile', { ...getCommonRequestOptions('user', { token: store.getState().auth.accessToken }) })
      .json();
  }

  async function acceptServiceTerms(serviceAgreementToken: string): Promise<AuthResponse> {
    return ky
      .post('service-terms/accept', { ...getCommonRequestOptions('user', { token: serviceAgreementToken }) })
      .json();
  }

  async function getTransactionLimit(transactionType: PaymentMethodRatesTransactionType): Promise<TransactionLimit> {
    return ky.get(`limits/${transactionType}`, { ...getCommonRequestOptions('user', { token }) }).json();
  }

  async function getTransactionSpent(transactionType: PaymentMethodRatesTransactionType): Promise<TransactionSpent> {
    return ky.get(`spent/${transactionType}`, { ...getCommonRequestOptions('user', { token }) }).json();
  }

  async function updatePreferredMerchantCategoryCode(payload: { merchantCategoryCodeId: string }): Promise<User> {
    return ky.post('update-mcc', { ...getCommonRequestOptions('user', { token }), json: payload }).json();
  }

  async function getUserCashBalanceSettings(): Promise<UserBalanceSettingsResponse> {
    return ky.get('cash-balance-settings', { ...getCommonRequestOptions('user', { token }) }).json();
  }

  async function acceptTermsCashBalance(): Promise<AuthResponse> {
    return ky
      .get('cash-balance-settings/accept-terms-and-conditions', { ...getCommonRequestOptions('user', { token }) })
      .json();
  }

  async function acceptTermsGiftCardBalance(): Promise<AuthResponse> {
    return ky
      .post('gift-card-balance-settings/accept-terms-and-conditions', { ...getCommonRequestOptions('user', { token }) })
      .json();
  }

  return {
    acceptTermsPrepaidReserveBalance,
    getPrepaidReserveBalance,
    getUserReserveBalanceSettings,
    getSpendingLimit,
    getWalletEarnings,
    getUserProfile,
    getMerchantSpent,
    acceptServiceTerms,
    getTransactionLimit,
    getTransactionSpent,
    updatePreferredMerchantCategoryCode,
    getUserCashBalanceSettings,
    acceptTermsCashBalance,
    acceptTermsGiftCardBalance,
  };
}
