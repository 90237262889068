interface FetchError extends Error {
  response: Response;
}

export function assertIsError(error: any): asserts error is Error {
  if (!(error instanceof Error)) throw new Error('Variable is not an instance of Error.');
}

export function assertIsFetchError(error: any): asserts error is FetchError {
  if (!(error instanceof Error)) throw new Error('Variable is not an instance of Error.');
  if (!Object.hasOwnProperty.call(error, 'response'))
    throw new Error('Error object does not have a response property.');
}
