/** @function removeEmpty
 *  Remove undefined or empty keys of a nested object
 *  This is used mostly with eliminating the filters
 *  we add to the request.
 */

export const removeEmpty = (obj: Record<string, any>) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') {
      removeEmpty(obj[key]);
    } else if (obj[key] === undefined || obj[key] === '') {
      delete obj[key];
    }
  });
  return obj;
};

export const base64ImgToSvg = (base64Img?: string) => (base64Img ? `data:image/svg;base64,${base64Img}` : null);
