import { ActionType } from 'typesafe-actions';

import * as auth from './actions';
import {
  RESET_AUTHENTICATION_STATE,
  SET_ACCESS_TOKEN,
  SET_REFRESH_TOKEN,
  SET_INITIAL_TOKEN,
  SET_PIN_AUTH_TOKEN,
} from './constants';

export type AuthAction = ActionType<typeof auth>;

export type AuthState = {
  isAuthenticated: boolean;
  initialToken: string | undefined;
  accessToken: string | undefined;
  refreshToken: string | undefined;
  pinAuthToken: string | undefined;
};

const defaultState: AuthState = {
  isAuthenticated: false,
  initialToken: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  pinAuthToken: undefined,
};

export default (state = defaultState, action: AuthAction): AuthState => {
  switch (action.type) {
    case SET_INITIAL_TOKEN:
      return {
        ...state,
        initialToken: action.payload,
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case SET_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.payload,
        isAuthenticated: true,
      };
    case SET_PIN_AUTH_TOKEN:
      return {
        ...state,
        pinAuthToken: action.payload,
      };
    case RESET_AUTHENTICATION_STATE:
      return {
        ...defaultState,
      };
    default:
      return state;
  }
};
