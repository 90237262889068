import classNames from 'classnames';
import React from 'react';

interface FormSectionProps {
  title?: string;
  subtitle?: string;
  titleRequired?: boolean;
  subtitleRequired?: boolean;
  children?: React.ReactNode;
  wrapperClassName?: string;
  className?: string;
}
export default function FormSection({
  title,
  subtitle,
  titleRequired = false,
  subtitleRequired = false,
  children,
  wrapperClassName,
  className,
}: FormSectionProps) {
  const RequiredText = (
    <span className="color-gray_400 font-weight-light">
      {' '}
      (Required) <span className="color-danger font-weight-bold">*</span>
    </span>
  );
  return (
    <div className={classNames('d-flex flex-column w-100 gap-3', wrapperClassName)}>
      {title && (
        <div className="form-section-title">
          {title}
          {titleRequired && RequiredText}
        </div>
      )}
      {subtitle && (
        <div className="form-section-subtitle">
          {subtitle}
          {subtitleRequired && RequiredText}
        </div>
      )}
      <div className={classNames('d-flex gap-3 w-100', className)}>{children}</div>
    </div>
  );
}
