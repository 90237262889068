import { ActionType } from 'typesafe-actions';
import * as alert from './actions';
import { ADD_ALERT, DISMISS_ALERT, DISMISS_ALERT_GROUP } from './constants';
import { AlertOptions } from '../../library/interfaces';

export type AlertAction = ActionType<typeof alert>;

const defaultAlertState: AlertOptions[] = [];

export default (state = defaultAlertState, action: AlertAction): AlertOptions[] => {
  switch (action.type) {
    case ADD_ALERT: {
      const existingAlert = state.find((alert) => alert.id === action.payload.id);
      if (existingAlert) {
        return state;
      }
      return [
        ...state,
        {
          id: action.payload.id,
          title: action.payload.title,
          message: action.payload.message,
          level: action.payload.level,
          position: action.payload.position ? action.payload.position : 'top-center',
          autohide: action.payload.autohide,
          dismissInMs: action.payload.dismissInMs,
          size: action.payload.size || 'lg',
        },
      ];
    }
    case DISMISS_ALERT:
      return state.filter((alert) => (alert.id === action.payload.id ? false : true));
    case DISMISS_ALERT_GROUP:
      return state.filter((alert) => (alert.id.includes(action.payload.id) ? false : true));
    default:
      return state;
  }
};
