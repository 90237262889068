import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { userReducer } from './user';
import { authReducer } from './auth';
import { settingReducer } from './setting';
import { alertsReducer } from './alerts';
import { loadBalancingSettingsReducer } from './load-balancing-settings';
import { paymentReducer } from './payment';
import { depositReducer } from './deposit';
import { virtualCardReducer } from './virtual-card';
import { signupFormReducer } from './signup-form';

export const rootReducer = () =>
  combineReducers({
    user: persistReducer({ key: 'user', storage }, userReducer),
    auth: persistReducer({ key: 'auth', storage }, authReducer),
    setting: settingReducer,
    alerts: alertsReducer,
    loadBalancingSettings: loadBalancingSettingsReducer,
    payment: paymentReducer,
    deposit: persistReducer({ key: 'deposit', storage }, depositReducer),
    virtualCard: virtualCardReducer,
    signupForm: signupFormReducer,
  });

export default rootReducer;
