import { createAlert } from '../../store/alerts/actions';
import { AlertLevel } from '../../library/enums';
import { useDispatch } from 'react-redux';

enum NotificationType {
  FORM = 'FORM',
  ALERT = 'ALERT',
}

interface NotificationDetails {
  level?: AlertLevel;
  type?: NotificationType;
  title?: string;
}

export interface FluzError {
  errorRecord: {
    code: string;
    name: string;
    message: string;
    status_code: number;
    user_friendly: boolean;
    notification_details: NotificationDetails;
  };
}

export interface ResponseError {
  success: boolean;
  statusCode: number;
  msg: string;
  error: FluzError | Record<string, unknown>;
}

export function useErrorHandler() {
  const dispatch = useDispatch();

  function showErrorAlert(title: string, message: string, level: AlertLevel = 'danger') {
    dispatch(
      createAlert({
        title,
        message,
        level,
      })
    );
  }

  const fallbackTitle = 'OOPS SOMETHING WENT WRONG';
  const fallbackMsg =
    'Sorry, we ran into an issue while processing your request, please refresh the page and try again or contact customer support';

  function parseResponseError(responseError: ResponseError, canHandleFormError = false) {
    const statusCode = responseError && responseError.statusCode ? responseError.statusCode : null;
    const isFluzError = !!responseError.error.errorRecord;
    let displayTitle = fallbackTitle;
    let displayMessage = responseError.msg ?? fallbackMsg;
    if (isFluzError) {
      const { errorRecord } = responseError.error as FluzError;
      const { message, notification_details } = errorRecord;
      const { level, type, title } = notification_details || {};
      displayTitle = title ?? fallbackTitle;
      displayMessage = message;
      if (type === 'FORM' && canHandleFormError) {
        return {
          type,
          message,
          level,
        };
      } else {
        showErrorAlert(displayTitle, message, level);
      }
    } else if (statusCode === 498) {
      return;
    } else {
      showErrorAlert(displayTitle, displayMessage);
    }
  }
  return { parseResponseError, showErrorAlert };
}
