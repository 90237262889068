import { action } from 'typesafe-actions';
import { BankAccount, MxInstitution, MxMember } from '../../library/interfaces';

import {
  SET_MX_GUID,
  SET_MX_MEMBERS,
  SET_MX_VERIFY_MEMBERS,
  SET_MX_BANK_BALANCES,
  ADD_ACH_METHODS,
  SET_MX_INSTITUTIONS,
  SET_SHOW_NEW_BANK_ACCOUNT_MODAL,
  SET_SHOW_VERIFY_ACCOUNT_MODAL,
  SET_SHOW_VERIFY_ACCOUNT_MEMBER,
  SET_SHOW_VERIFY_ACCOUNT_REMOVAL_MEMBER,
  SET_SELECTED_PAYMENT_CHALLENGED_MEMBER_GUID,
  SET_ADD_PAYPAL,
  SET_FETCH_MX_GUID,
  SET_RECENTLY_USED_PAYMENTS,
} from './constants';

export const setRecentlyUsedPayment = (recentlyUsedPayments: BankAccount[]) =>
  action(SET_RECENTLY_USED_PAYMENTS, recentlyUsedPayments);
export const setMxGuid = (mxGuid: string) => action(SET_MX_GUID, mxGuid);
export const setMxMembers = (mxMembers: MxMember[]) => action(SET_MX_MEMBERS, mxMembers);
export const setMxVerifyMembers = (members: { memberId: string; bankInstitutionAuthId?: string }[]) =>
  action(SET_MX_VERIFY_MEMBERS, members);
export const setMxBankBalance = (mxBankBalances: BankAccount[]) => action(SET_MX_BANK_BALANCES, mxBankBalances);
export const addACHMethods = (mxNewAchAccounts: any) => action(ADD_ACH_METHODS, mxNewAchAccounts);
export const setMxInstitutions = (mxInstitutions: MxInstitution[]) => action(SET_MX_INSTITUTIONS, mxInstitutions);
export const setShowNewBankAccountModal = (showNewBankAccountModal: boolean) =>
  action(SET_SHOW_NEW_BANK_ACCOUNT_MODAL, showNewBankAccountModal);
export const setFetchMxGUID = (shouldFetchMXGuid: boolean) => action(SET_FETCH_MX_GUID, shouldFetchMXGuid);
export const setShowVerifyAccountModal = (showVerifyAccountModal: boolean) =>
  action(SET_SHOW_VERIFY_ACCOUNT_MODAL, showVerifyAccountModal);
export const setVerifyAccountMember = (verifyAccountMember: any) =>
  action(SET_SHOW_VERIFY_ACCOUNT_MEMBER, verifyAccountMember);
export const setVerifyAccountRemovalMemberGuid = (verifyAccountRemovalMemberGuid: string) =>
  action(SET_SHOW_VERIFY_ACCOUNT_REMOVAL_MEMBER, verifyAccountRemovalMemberGuid);
export const setSelectedPaymentChallengedMemberGuid = (setSelectedPaymentChallengedMemberGuid: string) =>
  action(SET_SELECTED_PAYMENT_CHALLENGED_MEMBER_GUID, setSelectedPaymentChallengedMemberGuid);
export const setAddPaypal = (paypal: string) => action(SET_ADD_PAYPAL, paypal);
