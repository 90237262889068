import { BankAccount, BankCard, FormattedPaymentOption, PayPal, DepositPaymentOption } from '../../library/interfaces';
import { formatPaymentMethod } from './format-payment-method';
const windowWidth = window?.innerWidth;

export function mapPaymentSelectDisplayOption(paymentMethod: BankAccount | BankCard | PayPal) {
  const formattedMethod = formatPaymentMethod(paymentMethod) as FormattedPaymentOption;
  const { id, name, last_four, expiration } = formattedMethod;
  const maxLength =
    windowWidth <= 375
      ? 0
      : windowWidth <= 400
      ? 10
      : windowWidth <= 500
      ? 15
      : windowWidth <= 600
      ? 20
      : windowWidth <= 850
      ? 25
      : windowWidth <= 1100
      ? 30
      : 40;
  const formattedName =
    name && name?.length > maxLength ? (maxLength > 0 ? `${name?.slice(0, maxLength)}... ` : '') : name;
  return {
    ...formattedMethod,
    value: id,
    label: `${`${formattedName || ''} ${last_four || ''}${expiration || ''}`}`,
  };
}

export function mapBackupPaymentMethod(backupId: string, bankCards: BankCard[]) {
  const backupPaymentMethod =
    bankCards &&
    (bankCards
      .map((bankcard) => mapPaymentSelectDisplayOption(bankcard))
      .filter((card) => {
        return card.bank_card_id === backupId;
      })[0] as unknown as DepositPaymentOption);

  return backupPaymentMethod;
}
