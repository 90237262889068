import ky from 'ky';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { BusinessIndustryOptions, BusinessStructureOptions } from '../../library/enums';
import {
  BusinessSignupForm,
  BusinessSignupFormReq,
  BusinessSignupFormRes,
  Step1FormValues,
  Step2FormValues,
  Step3FormValues,
} from '../../library/interfaces';
import { setSignupForm } from '../../store/signup-form/actions';
import { SignupFormState } from '../../store/signup-form/reducer';
import useCommonRequestOptions from '../useCommonRequestOptions';
import { SignupFormRoutes } from '../../library/enums/SignupForm';

export type UploadProprietorshipDocRes = {
  webURL: string;
  file: string;
  originalName: string;
};

export default function useSignupFormService() {
  const token = useSelector((state: RootStateOrAny) => state.auth.accessToken);
  const dispatch = useDispatch();
  const { getCommonRequestOptions } = useCommonRequestOptions();
  const signupFormData: SignupFormState = useSelector((state: RootStateOrAny) => state.signupForm);

  async function getSignupForm() {
    const signupForm: BusinessSignupFormRes = await ky
      .get('', { ...getCommonRequestOptions('business-signup-form', { token }, false) })
      .json();
    dispatch(setSignupForm(parseSignupResToState(signupForm)));
  }

  function parseSignupResToState(res: BusinessSignupFormRes): BusinessSignupForm {
    const {
      business_signup_form_id,
      user_id,
      completed_steps,
      kyb_status,
      business_name,
      dba_name,
      business_structure,
      street_address_line_1,
      street_address_line_2,
      postal_code,
      city,
      state,
      proprietorship_doc,
      tax_id,
      owners,
      nature_of_business,
      website_url,
      business_industry,
      power_account_usage,
      power_account_usage_other,
    } = res;

    let step1: Step1FormValues | undefined;
    let step2: Step2FormValues | undefined;
    let step3: Step3FormValues | undefined;

    if (
      business_name ||
      dba_name ||
      business_structure ||
      street_address_line_1 ||
      street_address_line_2 ||
      postal_code ||
      city ||
      state ||
      proprietorship_doc?.originalName ||
      tax_id
    ) {
      step1 = {
        business_name,
        dbaCheckbox: !!dba_name,
        dba_name,
        business_structure: BusinessStructureOptions.find((structure) => structure.value === business_structure),
        street_address_line_1,
        street_address_line_2,
        postal_code,
        city,
        state,
        proprietorshipInput: proprietorship_doc?.originalName,
        tax_id,
      };
    }
    if (owners) {
      step2 = {
        owners,
      } as Step2FormValues;
    }
    if (nature_of_business || website_url || business_industry || power_account_usage || power_account_usage_other) {
      step3 = {
        nature_of_business,
        website_url,
        business_industry: BusinessIndustryOptions.find((industry) => industry.value === business_industry),
        power_account_usage,
        power_account_usage_other,
      };
    }
    return {
      business_signup_form_id,
      user_id,
      completedSteps: completed_steps,
      kyb_status,
      step1,
      step2,
      step3,
    };
  }

  function parseStateToSignupReq(data: Partial<BusinessSignupForm>): Partial<BusinessSignupFormReq> {
    const {
      completedSteps,
      kyb_status,
      step1: {
        business_name,
        dba_name,
        business_structure,
        street_address_line_1,
        street_address_line_2,
        postal_code,
        city,
        state,
        proprietorshipDoc,
        tax_id,
      } = {},
      step2: { owners } = {},
      step3: {
        nature_of_business,
        website_url,
        business_industry,
        power_account_usage,
        power_account_usage_other,
      } = {},
    } = data;
    return {
      completed_steps: completedSteps,
      kyb_status,
      business_name,
      dba_name,
      business_structure: business_structure?.value,
      street_address_line_1,
      street_address_line_2,
      postal_code,
      city,
      state,
      sole_proprietorship_document_url: proprietorshipDoc?.webURL,
      proprietorship_doc: proprietorshipDoc,
      tax_id,
      owners:
        owners && owners?.length > 0
          ? owners?.map(({ owner_since, ...owner }) => ({
              ...owner,
              owner_since: owner_since && !isEmpty(owner_since) ? moment(owner_since).format('YYYY-MM-DD') : undefined,
            }))
          : undefined,
      nature_of_business,
      website_url: !website_url && isEmpty(website_url) ? undefined : website_url,
      business_industry: business_industry?.value,
      power_account_usage,
      power_account_usage_other,
    };
  }

  async function updateSignupForm(signupForm: Partial<BusinessSignupForm>, cb?: Function) {
    const { step1: step1FromState, step2: step2FromState, step3: step3FromState } = signupFormData;
    const parsedData = parseStateToSignupReq(signupForm);

    const signupData: Partial<BusinessSignupForm> = {
      ...signupFormData,
      ...step1FromState,
      ...step2FromState,
      ...step3FromState,
      ...parsedData,
    };

    const res = await ky
      .patch('', {
        ...getCommonRequestOptions('business-signup-form', { token }, false),
        json: signupData,
      })
      .json<BusinessSignupFormRes>();

    dispatch(setSignupForm(parseSignupResToState(res), true));
    cb?.();
  }

  async function uploadProprietorshipDoc(file: File, cb?: Function) {
    const formData = new FormData();
    formData.append('proprietorship_doc', file);

    const commonRequestOptions = getCommonRequestOptions('business-signup-form', { token }, false);

    return await ky
      .post('sole-proprietorship', {
        ...commonRequestOptions,
        headers: {
          ...commonRequestOptions.headers,
          'Content-Type': undefined,
        },
        body: formData,
      })
      .json<UploadProprietorshipDocRes>();
  }

  async function submitApplication(cb?: Function) {
    const res = await ky
      .post('', {
        ...getCommonRequestOptions('business-signup-form', { token }, false),
      })
      .json<BusinessSignupFormRes>();
    dispatch(setSignupForm(parseSignupResToState(res)));
    cb?.();
  }

  return {
    getSignupForm,
    updateSignupForm,
    uploadProprietorshipDoc,
    submitApplication,
  };
}
