import { action } from 'typesafe-actions';

import {
  RESET_AUTHENTICATION_STATE,
  SET_ACCESS_TOKEN,
  SET_REFRESH_TOKEN,
  SET_INITIAL_TOKEN,
  SET_PIN_AUTH_TOKEN,
} from './constants';

export const setPinToken = (token: string) => action(SET_PIN_AUTH_TOKEN, token);
export const setAccessToken = (token: string) => action(SET_ACCESS_TOKEN, token);
export const setRefreshToken = (token: string) => action(SET_REFRESH_TOKEN, token);
export const setInitialToken = (token?: string) => action(SET_INITIAL_TOKEN, token);
export const resetAuth = () => action(RESET_AUTHENTICATION_STATE);
