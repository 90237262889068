export enum CardLinkedOffersCapType {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  PER_TRANSACTION = 'PER_TRANSACTION',
  LIFETIME = 'LIFETIME',
}

export enum CardLinkedOfferStatus {
  ON = 'ON',
  OFF = 'OFF',
}

export enum CardLinkedOfferPromoStatus {
  ON = 'ON',
  OFF = 'OFF',
}
