import { BankAccountType } from './BankAccount';
import { BankCardType } from './BankCard';
export type PaymentType =
  | 'BANK_ACCOUNT'
  | 'BANK_CARD'
  | 'PAYPAL'
  | 'FLUZPAY'
  | 'APPLE_PAY'
  | 'GOOGLE_PAY'
  | 'RESERVE_BALANCE';
export type PaymentSubType = BankAccountType | BankCardType | 'UNKNOWN';
export type PaymentMethodRestrictionType = 'LIMITED' | 'RESTRICTED';
export type PaymentMethodRatesTransactionType =
  | 'DEPOSIT'
  | 'GIFT_CARD'
  | 'VIRTUAL_CARD'
  | 'RESERVE_DEPOSIT'
  | 'DEPOSIT_GIFT_CARD_BALANCE';
export enum TransactionType {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
  GIFT_CARD = 'GIFT_CARD',
  GC_PREPAYMENT = 'GC_PREPAYMENT',
  VIRTUAL_CARD = 'VIRTUAL_CARD',
  RESERVE_DEPOSIT = 'RESERVE_DEPOSIT',
  DEPOSIT_GIFT_CARD_BALANCE = 'DEPOSIT_GIFT_CARD_BALANCE',
}

export enum FundingOptions {
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  BANK_CARD = 'BANK_CARD',
  PAYPAL = 'PAYPAL',
  FLUZPAY = 'FLUZPAY',
  APPLE_PAY = 'APPLE_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
}
