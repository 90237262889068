import { ActionType } from 'typesafe-actions';
import { DepositPaymentOption } from '../../library/interfaces';

import * as deposit from './actions';
import { SET_LAST_DEPOSIT } from './constants';

export type DepositAction = ActionType<typeof deposit>;

export type DepositState = {
  deposit_amount: number;
  funding_source: DepositPaymentOption | null;
};

const defaultState: DepositState = {
  deposit_amount: 0,
  funding_source: null,
};

export default (state = defaultState, action: DepositAction): DepositState => {
  switch (action.type) {
    case SET_LAST_DEPOSIT:
      return {
        ...defaultState,
        ...action.payload,
      };
    default:
      return state;
  }
};
