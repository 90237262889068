import { CButton, CCard, CCardBody, CCardGroup, CContainer, CForm, CLabel } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useFormContext } from 'react-hook-form';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import Checkmark from '../../../assets/images/misc-icons/checkmark.svg';
import { BusinessStructure, KybStatus } from '../../../library/enums';
import { SignupFormRoutes } from '../../../library/enums/SignupForm';
import { useInputState } from '../../../library/hooks/use-input-state/useInputState';
import { MainFormValues, Step1FormValues, Step2FormValues, Step3FormValues } from '../../../library/interfaces';
import useSignupFormService from '../../../services/signup-form/useSignupFormService';
import {
  setSignupFormCompletedSteps,
  setSignupFormLoading,
  setSignupFormShouldResetFields,
} from '../../../store/signup-form/actions';
import { SignupFormState } from '../../../store/signup-form/reducer';
import { useErrorHandler } from '../../../utils';
import { assertIsFetchError } from '../../../utils/type_assertions';
import { SIGNUP_FORM_STEPS } from '../constants';

type SetupProcessProps = {
  handleSubmitApplication(): void;
};
export default function SetupProcess({ handleSubmitApplication }: SetupProcessProps) {
  const history = useHistory();
  const location = useLocation();
  const { parseResponseError } = useErrorHandler();
  const dispatch = useDispatch();
  const { updateSignupForm, uploadProprietorshipDoc } = useSignupFormService();
  const {
    formState: { isValid, dirtyFields },
    getValues,
    resetField,
  } = useFormContext<MainFormValues>();
  const { isValid: isStep1Valid } = useInputState<MainFormValues>('step1');
  const { isValid: isStep2Valid } = useInputState<MainFormValues>('step2');

  const { isValid: isStep3Valid } = useInputState<MainFormValues>('step3');
  const signupFormData: SignupFormState = useSelector((state: RootStateOrAny) => state.signupForm);
  const disableField =
    (signupFormData.kyb_status && signupFormData.kyb_status !== KybStatus.IN_PROGRESS) || signupFormData.loading;
  const completedSteps = signupFormData.completedSteps || [];

  function handleSetCompleteSteps() {
    const steps = [];
    if (isStep1Valid) steps.push(SignupFormRoutes.COMPANY_INFO);
    if (isStep2Valid) steps.push(SignupFormRoutes.COMPANY_OWNERS);
    if (isStep3Valid) steps.push(SignupFormRoutes.COMPANY_ACTIVITY);
    dispatch(setSignupFormCompletedSteps(steps));
  }

  async function handleSaveData() {
    try {
      if (!disableField) {
        const isStep1Dirty = dirtyFields.step1;
        const isStep2Dirty = dirtyFields.step2;
        const isStep3Dirty = dirtyFields.step3;

        dispatch(setSignupFormLoading(true));

        let step1: Step1FormValues = {};
        let step2: Step2FormValues = {} as Step2FormValues;
        let step3: Step3FormValues = {};

        if (isStep1Dirty) {
          step1 = getValues('step1') || {};
        }
        if (isStep2Dirty) {
          step2 = (getValues('step2') as Step2FormValues) || {};
        }
        if (isStep3Dirty) {
          step3 = getValues('step3') || {};
        }

        if (
          step1?.business_structure?.value === BusinessStructure.SOLE_PROPRIETORSHIP &&
          step1?.proprietorshipInput &&
          dirtyFields.step1?.proprietorshipInput
        ) {
          step1.proprietorshipDoc = await uploadProprietorshipDoc(step1.proprietorshipInput);
          delete step1.proprietorshipInput;
          resetField('step1.proprietorshipInput', {
            defaultValue: step1.proprietorshipDoc.originalName,
          });
        }

        if (isStep1Dirty || isStep2Dirty || isStep3Dirty) {
          await updateSignupForm({ step1, step2, step3, completedSteps });
        }
      }
    } catch (error) {
      assertIsFetchError(error);
      const errorDetails = await error.response.json();
      parseResponseError({
        success: error.response.ok,
        statusCode: Number(error.response.status),
        msg: errorDetails.msg,
        error: errorDetails,
      });
    } finally {
      dispatch(setSignupFormLoading(false));
    }
  }

  useEffect(() => {
    handleSetCompleteSteps();
  }, [isStep1Valid, isStep2Valid, isStep3Valid]);

  useEffect(() => {
    handleSaveData();
  }, [location.pathname]);

  useEffect(() => {
    if (signupFormData.shouldResetField) {
      resetField('step1');
      resetField('step2');
      resetField('step3');
      dispatch(setSignupFormShouldResetFields(false));
    }
  }, [signupFormData.shouldResetField]);

  return (
    <CCardGroup>
      <CCard>
        <CCardBody>
          <CForm onSubmit={() => (disableField ? history.push('/') : handleSubmitApplication())}>
            {SIGNUP_FORM_STEPS.map(({ route, description }) => (
              <div
                key={route}
                className="d-flex justify-content-between align-items-center pt-2 pb-2 mb-3"
                onClick={() => history.push(route)}
              >
                <CLabel className={`mb-0 ${route === location.pathname ? 'input-label' : 'p-regular'}`}>
                  {description}
                </CLabel>
                <div
                  className="circle-mark"
                  style={{
                    borderColor: completedSteps.includes(route)
                      ? '#30DA42'
                      : route === location.pathname
                      ? '#3C4B64'
                      : '#9DA5B1',
                  }}
                >
                  {completedSteps.includes(route) && <img src={Checkmark} style={{ width: '12px', height: '12px' }} />}
                </div>
              </div>
            ))}
            <CButton
              id="traditional"
              type="submit"
              className="btn-submit fluz-gradient-primary mb-2"
              disabled={!isValid || signupFormData.loading}
              style={{ width: '100%' }}
            >
              {disableField && !signupFormData.loading ? 'Go to dashboard' : 'Submit your application'}
            </CButton>
            <p className="p-small-dark-gray_1000 text-center">
              Your progress will be saved automatically.
              <br />
              If you need assistance, please &nbsp;
              <a href="https://help.fluz.app/en/" target="_blank" rel="noopener noreferrer">
                contact support
              </a>
            </p>
          </CForm>
        </CCardBody>
      </CCard>
    </CCardGroup>
  );
}
