export enum VirtualCardTransactionActivityType {
  REFUND = 'REFUND',
  PURCHASE = 'PURCHASE',
  DISPUTE = 'DISPUTE',
  DECLINE = 'DECLINE',
  TEMPORARY_BANK_HOLD = 'TEMPORARY_BANK_HOLD',
}

export enum VirtualCardTransactionType {
  AUTH = 'AUTH',
  CAPTURE = 'CAPTURE',
}

export enum VirtualCardTransactionListStatus {
  COMPLETE = 'COMPLETED',
  PROCESSING = 'PROCESSING',
  PROCESSING_REFUND = 'PROCESSING_REFUND',
  PENDING_REFUND = 'PENDING_REFUND',
  CANCELLED = 'CANCELLED',
  DECLINED = 'DECLINED',
}

export enum VirtualCardTransactionDeclinedReason {
  UNKNOWN = 'UNKNOWN',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  PROGRAM_DOWN = 'PROGRAM_DOWN',
  CARD_CLOSED = 'CARD_CLOSED',
  INCORRECT_DETAILS = 'INCORRECT_DETAILS',
  AVS_TO_OTHER_MERCHANT = 'AVS_TO_OTHER_MERCHANT',
  CONTACT_SUPPORT = 'CONTACT_SUPPORT',
  DO_NOT_SEND = 'DO_NOT_SEND',
  INVALID_MERCHANT = 'INVALID_MERCHANT',
  USER_TRANSACTION_LIMIT = 'USER_TRANSACTION_LIMIT',
  UNKNOWN_HOST_TIMEOUT = 'UNKNOWN_HOST_TIMEOUT',
  INCORRECT_PIN = 'INCORRECT_PIN',
  INVALID_CARD_DETAILS = 'INVALID_CARD_DETAILS',
  DECLINED = 'DECLINED',
  BAD_CVC3 = 'BAD_CVC3',
  BAD_CVV = 'BAD_CVV',
  BAD_CVV2 = 'BAD_CVV2',
  BLOCKED_CARD = 'BLOCKED_CARD',
  CA_DECLINED = 'CA_DECLINED',
  CASH_BACK_LIMIT_EXCEEDED = 'CASH_BACK_LIMIT_EXCEEDED',
  CLOSED_ACCOUNT = 'CLOSED_ACCOUNT',
  DO_NOT_HONOR = 'DO_NOT_HONOR',
  EXCEEDS_APPROVAL_AMOUNT_LIMIT = 'EXCEEDS_APPROVAL_AMOUNT_LIMIT',
  EXPIRED_CARD = 'EXPIRED_CARD',
  INVALID_AUTHORIZATION_EXPIRATION = 'INVALID_AUTHORIZATION_EXPIRATION',
  INVALID_CARD_NUMBER = 'INVALID_CARD_NUMBER',
  INVALID_TRANSACTION = 'INVALID_TRANSACTION',
  NO_CARD_RECORD = 'NO_CARD_RECORD',
  PIN_DECRYPTION_ERROR = 'PIN_DECRYPTION_ERROR',
  RE_ENTER_TRANSACTION = 'RE_ENTER_TRANSACTION',
  RESTRICTED_LOCATION = 'RESTRICTED_LOCATION',
  SPECIAL_CONDITION_NO_PICK_UP = 'SPECIAL_CONDITION_NO_PICK_UP',
  SUSPENDED_CARD = 'SUSPENDED_CARD',
  TERMINATED_CARD = 'TERMINATED_CARD',
  TRANSACTION_NOT_PERMITTED = 'TRANSACTION_NOT_PERMITTED',
  UNACTIVATED_CARD = 'UNACTIVATED_CARD',
  VirtualCardExpired = 'VirtualCardExpired',
  VirtualCardInactive = 'VirtualCardInactive',
  InsufficientFluzpayBalance = 'InsufficientFluzpayBalance',
  InsufficientACHBalance = 'InsufficientACHBalance',
  InsufficientCombinedBalance = 'InsufficientCombinedBalance',
  UnauthorizedPayload = 'UnauthorizedPayload',
  OneTimeCardLimitExceeded = 'OneTimeCardLimitExceeded',
  PaymentMethodBlocked = 'PaymentMethodBlocked',
  PaymentMethodLimitExceeded = 'PaymentMethodLimitExceeded',
  FailedFraudGatewayReview = 'FailedFraudGatewayReview',
  MissingUserPermissions = 'MissingUserPermissions',
  ExceedsRemainingMerchantLimit = 'ExceedsRemainingMerchantLimit',
  ExceedsRemainingUserLimit = 'ExceedsRemainingUserLimit',
  ExceedsVirtualCardDailySpendLimit = 'ExceedsVirtualCardDailySpendLimit',
  ExceedsVirtualCardWeeklySpendLimit = 'ExceedsVirtualCardWeeklySpendLimit',
  ExceedsVirtualCardMonthlySpendLimit = 'ExceedsVirtualCardMonthlySpendLimit',
  ExceedsVirtualCardAnnualSpendLimit = 'ExceedsVirtualCardAnnualSpendLimit',
  ExceedsVirtualCardLifetimeSpendLimit = 'ExceedsVirtualCardLifetimeSpendLimit',
  VirtualCardLocked = 'VirtualCardLocked',
  MerchantDoesNotMatchBrandCard = 'MerchantDoesNotMatchBrandCard',
  UnableToFindVirtualCard = 'UnableToFindVirtualCard',
  DuplicateVirtualCardTransaction = 'DuplicateVirtualCardTransaction',
  VendorProgramLedgerInsufficient = 'VendorProgramLedgerInsufficient',
}

export enum ReversalStatus {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

export enum UserPurchaseStatus {
  PLACED = 'PLACED',
  PROCESSING = 'PROCESSING',
}
