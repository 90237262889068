import { CButton, CCard, CCardBody, CCardGroup, CCardHeader, CContainer, CForm } from '@coreui/react';
import moment from 'moment';
import React from 'react';
import { FieldArrayWithId, useFieldArray, useFormContext } from 'react-hook-form';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { z } from 'zod';
import FButton from '../../../components/common/fbutton/FButton';
import FormDisclaimer from '../../../components/inputs/FormDisclaimer';
import FormSection from '../../../components/inputs/FormSection';
import TextInput from '../../../components/inputs/TextInput';
import { KybStatus } from '../../../library/enums';
import { SignupFormRoutes } from '../../../library/enums/SignupForm';
import { MainFormValues } from '../../../library/interfaces';
import { SignupFormState } from '../../../store/signup-form/reducer';
import { ERROR_MESSAGES, getPercentageErrorMessage } from '../../../utils/form/error-messages';
import { emptyOwnerFormValues } from '../constants';

export const ownersSchema = z.object({
  owners: z
    .object({
      first_name: z.string({ required_error: ERROR_MESSAGES.required }).min(1, ERROR_MESSAGES.required),
      last_name: z.string({ required_error: ERROR_MESSAGES.required }).min(1, ERROR_MESSAGES.required),
      email_address: z
        .string({ required_error: ERROR_MESSAGES.required })
        .email(ERROR_MESSAGES.invalid.email)
        .min(1, ERROR_MESSAGES.required),
      title: z.string({ required_error: ERROR_MESSAGES.required }).min(1, ERROR_MESSAGES.required),
      ownership_percentage: z.string({ required_error: ERROR_MESSAGES.required }),
      owner_since: z
        .string({ required_error: ERROR_MESSAGES.required })
        .refine((value) => !!(value && moment(value).isValid()), ERROR_MESSAGES.invalid.date)
        .refine(
          (value) => !!(value && moment(value).isValid() && moment(value).isBefore(moment())),
          ERROR_MESSAGES.invalid.dateInFuture
        ),
    })
    .array(),
});

type FormValues = z.infer<typeof ownersSchema>;

export default function Owners() {
  const history = useHistory();
  const signupFormData: SignupFormState = useSelector((state: RootStateOrAny) => state.signupForm);

  const disableField =
    (signupFormData.kyb_status && signupFormData.kyb_status !== KybStatus.IN_PROGRESS) || signupFormData.loading;

  const {
    control,
    formState: { errors },
    trigger,
    setError,
    getValues,
    clearErrors,
  } = useFormContext<MainFormValues>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'step2.owners',
  });

  async function handleContinue() {
    if (!disableField || fields.length <= 4) {
      const isStep2Valid = await trigger('step2.owners');
      const fieldValues = getValues('step2.owners');
      let percentageError = false;
      let totalPercentage = 0;
      fieldValues.forEach((field, index, arr) => {
        const maxValue = 100 - (arr.length - 1) * 25;
        if (Number(field.ownership_percentage) < 25 || Number(field.ownership_percentage) > maxValue) {
          setError(`step2.owners.${index}.ownership_percentage`, { message: getPercentageErrorMessage(maxValue) });
          percentageError = true;
        }
        totalPercentage = totalPercentage + Number(field.ownership_percentage);
      });

      if (!percentageError && totalPercentage !== 100) {
        fieldValues.forEach((_, index) => {
          setError(`step2.owners.${index}.ownership_percentage`, { message: ERROR_MESSAGES.invalid.percentage });
        });
        percentageError = true;
      }

      if (!percentageError) {
        fieldValues.forEach((_, index) => {
          clearErrors(`step2.owners.${index}.ownership_percentage`);
        });
      }

      if (isStep2Valid && !percentageError) {
        history.push(SignupFormRoutes.COMPANY_ACTIVITY);
      }
    } else {
      history.push(SignupFormRoutes.COMPANY_ACTIVITY);
    }
  }

  function renderOwnerCard(owner: FieldArrayWithId<FormValues>, index: number) {
    return (
      <CCard key={owner.id} className="w-100" style={{ boxShadow: 'none' }}>
        <CCardHeader className="d-flex justify-content-between p-3 bg-gray_50">
          <h2 className="mb-1">Owner {index + 1}</h2>
          {index >= 1 && !disableField && (
            <CButton
              id="traditional"
              type="button"
              onClick={() => remove(index)}
              className="px-0 btn-link color-warning"
              disabled={disableField}
            >
              Remove
            </CButton>
          )}
        </CCardHeader>
        <CCardBody className="p-3">
          <FormSection title="Personal information" titleRequired>
            <TextInput
              placeholder="First name"
              control={control}
              name={`step2.owners.${index}.first_name`}
              error={errors?.step2?.owners?.[index]?.first_name?.message}
              fullWidth
              disabled={disableField}
            />
            <TextInput
              placeholder="Last name"
              control={control}
              name={`step2.owners.${index}.last_name`}
              error={errors?.step2?.owners?.[index]?.last_name?.message}
              fullWidth
              disabled={disableField}
            />
            <TextInput
              placeholder="Email"
              type="email"
              control={control}
              name={`step2.owners.${index}.email_address`}
              error={errors?.step2?.owners?.[index]?.email_address?.message}
              fullWidth
              disabled={disableField}
            />
          </FormSection>
          <FormSection subtitle="Ownership">
            <TextInput
              placeholder="Position"
              control={control}
              name={`step2.owners.${index}.title`}
              error={errors?.step2?.owners?.[index]?.title?.message}
              fullWidth
              disabled={disableField}
            />
            <TextInput
              placeholder="Owner since (MM/DD/YYYY)"
              control={control}
              name={`step2.owners.${index}.owner_since`}
              error={errors?.step2?.owners?.[index]?.owner_since?.message as string}
              fullWidth
              disabled={disableField}
              type="date"
            />
            <TextInput
              placeholder="Ownership percentage"
              control={control}
              type="percentage"
              name={`step2.owners.${index}.ownership_percentage`}
              error={errors?.step2?.owners?.[index]?.ownership_percentage?.message}
              fullWidth
              disabled={disableField}
            />
          </FormSection>
        </CCardBody>
      </CCard>
    );
  }

  return (
    <CCardGroup>
      <CCard>
        <CCardHeader className="pl-4 pt-4 pb-2">
          <h2 className="mb-1">Company Owners</h2>
        </CCardHeader>
        <CCardBody className="p-4">
          <FormDisclaimer>
            All controlling executives and beneficial owners (with more than 25% ownership) must have an individual Fluz
            account for security and monitoring purposes. If they do not have their own account, they can easily sign-up
            on our mobile app.
          </FormDisclaimer>
          <CForm className="mt-2 mb-2">
            {fields.map(renderOwnerCard)}
            <div className="d-flex flex-column gap-3">
              {!disableField && fields.length < 4 && (
                <CButton
                  id="traditional"
                  type="button"
                  disabled={disableField}
                  onClick={() => append(emptyOwnerFormValues.owners[0])}
                  color="link"
                  className="px-0 btn-link"
                >
                  Add additional owner
                </CButton>
              )}
              <FButton
                id="traditional"
                onClick={handleContinue}
                className="btn-submit fluz-gradient-primary"
                loading={signupFormData.loading}
              >
                Continue
              </FButton>
            </div>
          </CForm>
        </CCardBody>
      </CCard>
    </CCardGroup>
  );
}
