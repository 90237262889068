export const ERROR_MESSAGES = {
  required: 'This field is required.',
  invalid: {
    email: 'This email is invalid.',
    percentage: 'Sum of the percentage must be 100',
    ein: 'This is invalid EIN number.',
    date: 'Invalid date. (MM/DD/YYYY)',
    dateInFuture: "Date can't be in future",
    phone: 'This is invalid format of phone number.',
    url: 'This is invalid format of website URL.',
  },
};

export function getPercentageErrorMessage(maxPercentage: number): string {
  const minValue = 25;
  if (minValue === maxPercentage) return 'Value must be 25';
  return `Value must be between 25 and ${maxPercentage}`;
}
