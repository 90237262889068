export type UserPrivacyMode = 'PRIVATE' | 'INVITE' | 'PUBLIC';
export type UserVerificationType = 'INDIVIDUAL' | 'BUSINESS';

export enum FraudStatus {
  PASS = 'PASS',
  REVIEW = 'REVIEW',
  FAIL = 'FAIL',
  LOCKED = 'LOCKED',
  PENDING_DOCUMENTS = 'PENDING_DOCUMENTS',
}

export enum AmlStatus {
  PASS = 'PASS',
  PENDING_DOCUMENTS = 'PENDING_DOCUMENTS',
  INVESTIGATION = 'INVESTIGATION',
  INTERNAL_INVESTIGATION = 'INTERNAL_INVESTIGATION',
  REVIEW = 'REVIEW',
  FAIL = 'FAIL',
  SPOT_CHECK = 'SPOT_CHECK',
}

export enum KycStatus {
  NOT_REQUIRED = 'NOT_REQUIRED',
  PENDING_DOCUMENTS = 'PENDING_DOCUMENTS',
  MISSING_1099_INFO = 'MISSING_1099_INFO',
  PASS = 'PASS',
  PASS_INTERNATIONAL = 'PASS_INTERNATIONAL',
  SOFT_DECLINE = 'SOFT_DECLINE',
  HARD_DECLINE = 'HARD_DECLINE',
  FAIL = 'FAIL',
}
