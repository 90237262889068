import { useState, useEffect, useRef } from 'react';
import usePayment from '../../../services/payment/usePayment';
import { useErrorHandler } from '../../../utils/error/error-handler';
import { assertIsFetchError } from '../../../utils/type_assertions';
import { setRecentlyUsedPayment } from '../../../store/payment/actions';
import { useDispatch } from 'react-redux';

export default function useLastUsedPayment(refreshPayments: boolean) {
  const dispatch = useDispatch();
  const { getLastUsedPayments } = usePayment();
  const { parseResponseError } = useErrorHandler();
  const mounted = useRef<boolean>();
  const [state, setState] = useState(() => ({
    isLoading: false,
    page: 1,
    pageLimit: 50,
    payments: [],
    currentCount: 0,
    fetchedBankAccounts: 0,
    fetchedPaypals: 0,
  }));
  async function fetchLastUsedPaymentMethods() {
    try {
      setState({ ...state, isLoading: true });
      const {
        count,
        fetched_bank_accounts,
        fetched_paypals,
        rows: fetched_payments,
      } = await getLastUsedPayments(state.page, state.pageLimit, state.fetchedBankAccounts, state.fetchedPaypals);

      if (mounted.current) {
        dispatch(setRecentlyUsedPayment(fetched_payments));
        setState({
          ...state,
          isLoading: false,
          currentCount: state.currentCount + count,
          fetchedBankAccounts: fetched_bank_accounts,
          fetchedPaypals: fetched_paypals,
          payments: fetched_payments,
        });
      }
    } catch (error) {
      assertIsFetchError(error);
      const errorDetails = await error.response.json();
      parseResponseError({
        success: error.response.ok,
        statusCode: Number(error.response.status),
        msg: errorDetails.msg,
        error: errorDetails,
      });
      mounted.current ? setState({ ...state, isLoading: false }) : null;
    }
  }

  useEffect(() => {
    mounted.current = true;
    fetchLastUsedPaymentMethods();
    return () => {
      mounted.current = false;
    };
  }, [refreshPayments]);

  return {
    ...state,
    setPaymentsOptions: setState,
    fetchLastUsedPaymentMethods,
  };
}
