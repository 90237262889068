import React, { ReactElement } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { AlertPosition } from '../../../library/enums';
import { AlertOptions } from '../../../library/interfaces';
import Alert from './Alert';

const AlertPositionDetails: Record<AlertPosition, { top: number; right?: string | number; left?: string | number }> = {
  'top-right': {
    top: 34,
    right: 21,
  },
  'top-center': {
    top: 5,
    left: '30%',
    right: '30%',
  },
  'top-left': {
    top: 34,
    left: 21,
  },
};

export default function AlertOverlayComponent(): ReactElement {
  const alerts = useSelector((state: RootStateOrAny) => state.alerts);

  if (alerts && alerts.length) {
    // Mapping all available alerts, grouping by position of the alert.
    const mappedStackedAlerts: Record<string, AlertOptions[]> = alerts.reduce(
      (stackedAlerts: Record<string, AlertOptions[]>, alert: AlertOptions) => {
        const alertPosition = alert.position ? alert.position : 'top-center';
        stackedAlerts[alertPosition] = stackedAlerts[alertPosition] || [];
        stackedAlerts[alertPosition].push(alert);
        return stackedAlerts;
      },
      {}
    );

    return (
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: 'relative',
          zIndex: 5010,
        }}
      >
        {Object.keys(mappedStackedAlerts).map((alertPosition) => (
          /*Each unique position, will get it's Alert parent // Needed so we could stack alerts by 
            same position one under another. */
          <div
            style={{
              position: 'fixed',
              ...AlertPositionDetails[alertPosition as AlertPosition],
            }}
            key={`alert:${alertPosition}`}
          >
            {mappedStackedAlerts[alertPosition].map((alert: AlertOptions) => (
              <Alert alert={alert} key={alert.id} />
            ))}
          </div>
        ))}
      </div>
    );
  } else {
    return <></>;
  }
}
