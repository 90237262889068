import { CModal, CModalHeader, CModalBody, CModalFooter } from '@coreui/react';
import React, { ReactElement, ReactNode } from 'react';

interface ModalProps {
  size?: '' | 'sm' | 'lg' | 'xl' | undefined;
  show: boolean;
  onClose: Function;
  headerText?: string;
  children?: ReactNode;
  footer?: ReactNode;
  [x: string]: any;
}

export default function Modal({
  size = '',
  show = false,
  onClose,
  headerText,
  children,
  footer,
  ...props
}: ModalProps): ReactElement {
  return (
    <>
      <CModal size={size} centered show={show} onClose={onClose} {...props}>
        {!!headerText && (
          <CModalHeader
            closeButton
            className="card-title-left"
            style={{ paddingLeft: 22, paddingTop: 15, paddingBottom: 11 }}
          >
            {headerText}
          </CModalHeader>
        )}
        {headerText ? (
          <CModalBody style={{ paddingLeft: 22, paddingRight: 15, paddingTop: 15 }}>{children}</CModalBody>
        ) : (
          children
        )}
        {!!footer && (
          <CModalFooter style={{ paddingLeft: 22, paddingRight: 15, paddingTop: 15, paddingBottom: 20 }}>
            {footer}
          </CModalFooter>
        )}
      </CModal>
    </>
  );
}
