import React, { ReactElement } from 'react';
import classNames from 'classnames';
interface LabelProps {
  className?: string;
  children: ReactElement | string;
  [x: string]: any;
}
export default function Label({ children, className, ...props }: LabelProps) {
  return (
    <span className={classNames(['fluz-label label-content text-uppercase', className])} {...props}>
      {children}
    </span>
  );
}
